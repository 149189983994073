<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, DictionaryType, DictionaryEntry, instanceOfDictionaryEntry } from '.';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import DataSourcesService from '@/modules/core/data-sources/services/DataSourcesService';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: DictionaryType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new DictionaryEntry(), instanceOfDictionaryEntry) as DictionaryEntry);
const display = computed(() => entry.value.text);
const placeholder = computed(() =>
{
    return component.value.placeholder || $t('[[[wybierz...]]]');
});
const model = computed({
    get()
    {
        return {
            value: entry.value.value,
            text: entry.value.text
        };
    },
    set(model)
    {
        entry.value.value = model.value;
        entry.value.text = model.text;
    }
});

const reset = (): void =>
{
    entry.value.value = null;
    entry.value.text = null;
};

const fetch = async (value: string): Promise<Option> =>
{
    return new Promise(resolve => resolve(model.value));
};

const search = async (query: string): Promise<Option[]> =>
{
    const pager = new Pager(1, 10, 'Position', 'ASC');
    const response = await DataSourcesService.getOptions(component.value.sourceId, query, pager);

    return response.items.map(p =>
    {
        return {
            value: p.value.toString(),
            text: p.text
        };
    });
};

const onChanged = (value: Option): void =>
{
    model.value = value;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-autocomplete :name="component.name" :placeholder="placeholder" :fetch="fetch" :search="search" :model-value="model.value" @changed="onChanged" />
    </ideo-filter>
</template>
