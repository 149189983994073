<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { FluentFormBuilder } from '../..';
import { PublicationEnum, PublicationStyle } from '@/helpers/Enums';
import { Resource, Columns } from '@/helpers/Interfaces';
import { Form, FormType } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { useLogging } from '@/plugins/logging';
import { useAlerts } from '@/plugins/alerts';
import { useMixins } from '@/plugins/mixins';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { FormContract, FormEntry } from '@/components/forms/blueprints/form';
import EntriesService, { FilterModel, ListItemModel } from '@/modules/studio/entries/services/EntriesService';
import FormsService from '@/modules/studio/forms/services/FormsService';

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $handleException, $redirect } = useMixins();
const { $t } = useLocalization();

const props = defineProps({
  "categoryId": null,
  "entryId": null,
  "formId": null,
  "parentId": null
});
const formId = computed(() => props.formId);

const loaded = ref(false);
const builder = ref<FormBuilderContract>(null);
const blueprint = ref<FormContract>(null);
const items = ref<Resource<ListItemModel>[]>([]);
const pager = ref<Pager>(new Pager(1, 20, 'DateCreatedUtc', 'DESC'));

const permissions = ref({
    canCreate: false
});
const columns = ref<Columns>({
    visible: {},
    positions: {}
});
const filter = ref<FormType<FilterModel>>(Form.create<FilterModel>({
    formId: null,
    userId: 0,
    status: null,
    title: '',
    dateFromUtc: null,
    dateDueUtc: null,
    onlyMine: false,
    document: {} as FormEntry,
    relatedTo: []
}));

const loadForm = async (): Promise<void> =>
{
    try
    {
        if (props.formId > 0)
        {
            const response = await FormsService.fetchPublished(props.formId);

            builder.value = FluentFormBuilder
                .setup()
                .blueprint(() => response.result.definition, () => props.formId)
                .entry(() => filter.value.document, () => props.entryId)
                .make();

            blueprint.value = builder.value.getBlueprint() as FormContract;
            filter.value.document = builder.value.getEntry() as FormEntry;
        }
    }
    catch (ex)
    {
        $handleException(ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
};

const loadData = async (): Promise<void> =>
{
    loaded.value = false;
    items.value = [];

    if (!props.formId)
    {
        pager.value.setTotalRows(0);
        items.value = [];

        return;
    }

    try
    {
        filter.value.relatedTo = [props.entryId];

        const response = await EntriesService.getList({
            ...filter.value.data(),
            formId: props.formId
        }, pager.value);

        pager.value.setTotalRows(response.totalRows);
        permissions.value = response.meta;
        items.value = response.items;
    }
    catch (ex)
    {
        pager.value.setTotalRows(0);
        items.value = [];
        $log.debug(ex);
    }
    finally
    {
        loaded.value = true;
    }
};

onMounted(() =>
{
    loadForm();
    loadData();
});

const gotoForm = (item: Resource<ListItemModel> = null): void =>
{
    if (item != null && item.meta.canEdit)
        $redirect({ name: 'core-entries-edit', params: { id: item.result.id } });
    else if (item == null)
        $redirect({ name: 'core-entries-add', params: { categoryId: props.categoryId, formId: props.formId } });
};

const statusStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    return PublicationStyle(classTemplate, status);
};

watch(formId, async (value: number): Promise<void> =>
{
    await loadForm();
    await loadData();
});
</script>

<template>
    <form-list-view
        :loaded="loaded" :builder="builder"
        :items="items" :columns="columns" :pager="pager" @change="loadData()"
        :row-click="(item: Resource<ListItemModel>) => gotoForm(item)"
        :row-class="(item: Resource<ListItemModel>) => ({ ...statusStyle('data-{0}', item.result.status), 'text-muted': !item.meta.canEdit })"
        :column-value="(item: Resource<ListItemModel>, column: string) => item.result.document[column]"
    >
        <template #row-start="{item}: any">
            <list-view-item :title="$t('[[[Id]]]')" sort="Id" width="50">
                {{ item.result.id }}
            </list-view-item>
            <list-view-item :title="$t('[[[Data dodania]]]')" sort="DateCreatedUtc" width="120">
                {{ $filters.datetime(item.result.dateCreatedUtc) }}
            </list-view-item>
        </template>
        <!-- <template #row-end="{item}: any">
            <list-view-buttons width="50">
                <list-view-button :title="$t('[[[Edytuj]]]')" :disabled="!item.meta.canEdit" @click="gotoForm(item)">
                    <i class="fas fa-pencil-alt me-1"></i> {{ $t('[[[Edytuj]]]') }}
                </list-view-button>
                <list-view-button :title="$t('[[[Usuń]]]')" :disabled="!item.meta.canDelete" @confirm="deleteItem" :message="$t('[[[Potwierdzenie usunięcia]]]')">
                    <i class="fas fa-times me-2"></i> {{ $t('[[[Usuń]]]') }}
                </list-view-button>
            </list-view-buttons>
        </template> -->
    </form-list-view>
    <pagination-bar :pager="pager" @change="loadData()" />
</template>

@/modules/studio/forms/services/FormsService
