<template>
    <button ref="$el" :type="buttonType" :class="[buttonClass, {'text-nowrap': nowrap}]" :title="title" @click="onClick">
        <i :class="[iconClass, {'d-none d-sm-inline': buttonType === 'submit' && !dropdown}]"></i>
        <span v-if="textVisible">{{ buttonText }}</span>
        <confirmation :message="confirm" :target="$refs.$el" placement="auto" :value="{}" @confirm="onConfirm" v-if="loaded && confirm.length > 0" />
    </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'action-button'
})
export default class ActionButton extends Vue
{
    @Prop() public toolbar: boolean;
    @Prop() public submit: boolean;
    @Prop() public to: Record<string, any>;
    @Prop() public back: boolean;
    @Prop() public icon: string;
    @Prop() public text: string;
    @Prop() public title: string;
    @Prop({ default: '' }) public confirm: string;
    @Prop({ default: 'primary' }) public variant: string;
    @Prop({ default: true }) public nowrap: boolean;

    public loaded: boolean = false;
    public dropdown: boolean = false;
    public floating: boolean = false;

    public get condition(): boolean
    {
        if ('condition' in this.$parent)
            return (this.$parent as any).condition;
        else
            return this.mobile;
    }

    public get standardButton(): boolean
    {
        return !this.condition;
    }

    public get backButton(): boolean
    {
        return this.condition && (this.back !== undefined && this.back !== false) && !this.dropdownButton && !this.floatingButton;
    }

    public get dropdownButton(): boolean
    {
        return this.condition && this.dropdown;
    }

    public get floatingButton(): boolean
    {
        return this.condition && this.floating;
    }

    public get buttonType(): 'button' | 'submit'
    {
        return this.submit !== undefined && this.submit !== false ? 'submit' : 'button';
    }

    public get buttonClass(): string
    {
        if (this.dropdownButton)
            return 'dropdown-item';

        if (this.floatingButton)
            return `btn btn-${this.variant} btn-float rounded-circle`;

        if (this.backButton)
            return 'btn btn-light bg-white border-0 back-button';

        return `btn btn-${this.variant}`;
    }

    public get iconClass(): string
    {
        if (this.dropdownButton)
            return `${this.icon} ${this.iconSpace}`;

        if (this.floatingButton)
            return `${this.icon} fa-fw`;

        if (this.backButton)
            return `fas fa-arrow-left fa-back`;

        return `${this.icon} ${this.iconSpace}`;
    }

    public get iconSpace(): string
    {
        return this.textVisible ? 'me-2' : '';
    }

    public get textVisible(): boolean
    {
        if (this.dropdownButton)
            return true;

        if (!this.text)
            return false;

        if (this.floatingButton)
            return false;

        if (this.backButton)
            return false;

        return true;
    }

    public get buttonText(): string
    {
        if (this.dropdownButton)
            return this.text || this.title;

        return this.text;
    }

    public mounted(): void
    {
        this.dropdown = this.$el.parentElement.classList.contains('dropdown-menu');
        this.floating = this.$el.parentElement.classList.contains('floating');
        this.loaded = true;
    }

    public onClick(e: any): void
    {
        if (this.confirm.length == 0)
        {
            this.onConfirm(e);
        }
    }

    public onConfirm(e: any): void
    {
        if (this.to)
            this.redirect(this.to);
        else
            this.triggerClick(e);
    }

    @Emit('click')
    public triggerClick(e: any): any
    {
        return e;
    }
}
</script>

<style lang="scss" scoped>
.btn-float {
    padding: 12px !important;

    .fa-fw {
        display: block !important;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
    }
}

.fa-back {
    font-size: 16px;
}

.back-button {
    padding-left: 0;
    transform: translate(8px, 0);
    box-shadow: none !important;
}
</style>
