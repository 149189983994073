<script lang="ts" setup>
import { computed, shallowRef, onBeforeMount, ref } from 'vue';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import properties from '../../properties';
import { FormEntry } from '../form';
import { CompositeType, CompositeEntry, instanceOfCompositeEntry } from '.';
import { parseToComponent } from './helpers';
import { useLogging } from '@/plugins/logging';

defineOptions({
    name: 'composite-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "form": { default: null },
  "parent": { default: null },
  "blueprint": { default: null },
  "entry": { default: null }
});

const { $log } = useLogging();
const composite = ref(null);
const blueprint = computed(() => props.blueprint);
const entryData = props.form.document.initEntry(props.blueprint, new CompositeEntry(), instanceOfCompositeEntry);

function getProperties(): any[]
{
    const properties =  [];

    if (composite.value.properties)
    {
        for (const item of composite.value.properties())
        {
            properties.push(item);

            if (item.$property !== undefined && item.defaultValue !== undefined && blueprint.value[item.$property] === undefined)
            {
                blueprint.value[item.$property] = item.defaultValue;
            }
        }
    }

    return properties;
}

function prepare(item: Record<string, any>): Record<string, any>
{
    return Object.entries(item)
        .where(([key]) => !['$type', '$property', 'defaultValue'].includes(key))
        .select(([key, value]) =>
        {
            if (typeof value == 'function')
                return {key: key, value: value(props.blueprint)};
            else
                return {key: key, value: value};
        })
        .record(p => p.key, p => p.value);
}

function visible(item: Record<string, any>): boolean
{
    if (item.visible == undefined)
        return true;

    if (typeof item.visible == 'function')
        return item.visible(props.blueprint);

    return item.visible;
}

const module = shallowRef(null);

onBeforeMount(async () =>
{
    try
    {
        const key = blueprint.value.composite;
        const baseUrl = import.meta.env.VITE_APP_API_URL;
        const response = await fetch(`${baseUrl}/admin/forms/components/${key}.vue`);

        if (!response.ok)
        {
            throw new Error("Network response was not OK");
        }

        module.value = parseToComponent(key, await response.text());
    }
    catch (ex)
    {
        $log.error(ex);
    }
});
</script>

<template>
    <form-component-wrapper :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <component :is="module" :form="form" :blueprint="blueprint" :entry="entryData" ref="composite" v-if="module"></component>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <template v-if="composite">
                <div v-for="(item, i) in getProperties()" :key="i">
                    <component
                        :is="`field-${item.$type}`"
                        v-model="blueprint[item.$property]"
                        :form="form"
                        :blueprint="blueprint"
                        :entry="entry"
                        v-bind="prepare(item)"
                        v-if="visible(item)"
                    ></component>
                </div>
            </template>
        </template>
    </form-component-wrapper>
</template>
