import { DateTime } from 'luxon';
import { SchemaManager } from '../../base/managers/SchemaManager';
import { DocumentManager } from './DocumentManager';

// Blueprints
import { Blueprint } from '../../base/blueprints/Blueprint';
import { instanceOfVisibleBlueprint } from '../blueprints/VisibleBlueprint';
import { instanceOfReadonlyBlueprint } from '../blueprints/ReadonlyBlueprint';
import { instanceOfRequiredBlueprint } from '../blueprints/RequiredBlueprint';
import { CustomErrorBlueprint, instanceOfCustomErrorBlueprint } from '../blueprints/CustomErrorBlueprint';

// Enums
import { AlwaysChoice } from '../enums/AlwaysChoice';
import { InternallyChoice } from '../enums/InternallyChoice';
import { NeverChoice } from '../enums/NeverChoice';
import { WhenChoice } from '../enums/WhenChoice';

// Services
import { StateStore } from '../../base/services/StateStore';

// --------------------------------------------------

export class ExpressionManager
{
    private globals: any = null;
    private state: StateStore;
    private schema: SchemaManager;
    private document: DocumentManager;

    public constructor(state: StateStore, schemaManager: SchemaManager, documentManager: DocumentManager)
    {
        this.state = state;
        this.schema = schemaManager;
        this.document = documentManager;
    }

    private compileCode(expression: string): any
    {
        if (this.globals == null)
        {
            const validKey = new RegExp('^[a-zA-Z_$][0-9a-zA-Z_$]*$');

            this.globals = Object.keys(globalThis)
                .filter(k => validKey.test(k))
                .reduce((o, key) => ({ ...o, [key]: undefined }), {});
        }

        const variables = {
            globalThis: undefined,
            XMLHttpRequest: undefined,
            GeneratorFunction: undefined,
            AsyncFunction: undefined,
            Function: undefined,
            Object: undefined,
            console: undefined,
            eval: undefined,
            ...this.globals,
            DateTime: DateTime,
            Math: Math,
            Form: this.getProxy(),
            Entry: this.document.getRootEntry()
        };

        // eslint-disable-next-line no-new-func
        const code = new Function(...Object.keys(variables), `return (${expression})`);

        return code(...Object.values(variables));
    }

    private getProxy(): BlueprintManagerProxy
    {
        return new BlueprintManagerProxy(this.schema);
    }

    public checkExpression(expression: string): any
    {
        try
        {
            if (expression)
            {
                this.compileCode(expression);
            }

            return null;
        }
        catch (ex)
        {
            return ex;
        }
    }

    public executeExpression(expression: string): any
    {
        try
        {
            if (expression)
            {
                return this.compileCode(expression);
            }
        }
        catch (ex) { /**/ }

        return null;
    }

    public visible(blueprint: Blueprint, ancestors: boolean = false): boolean
    {
        let result = true;

        if (blueprint && instanceOfVisibleBlueprint(blueprint))
        {
            if (this.state.design)
            {
                result = blueprint.visible == AlwaysChoice.Always;
            }
            else
            {
                switch (blueprint.visible)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case InternallyChoice.Internally:
                        result = this.state.internal;
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.visibleWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        if (ancestors == true)
        {
            do
            {
                blueprint = this.schema.parent(blueprint);
                result = result && this.visible(blueprint);
            }
            while (blueprint != null);
        }

        return result;
    }

    public readonly(blueprint: Blueprint, ancestors: boolean = false): boolean
    {
        let result = false;

        if (blueprint && instanceOfReadonlyBlueprint(blueprint))
        {
            if (this.state.design)
            {
                result = blueprint.readonly != NeverChoice.Never;
            }
            else
            {
                switch (blueprint.readonly)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case InternallyChoice.Internally:
                        result = this.state.internal;
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.readonlyWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        if (ancestors == true)
        {
            do
            {
                blueprint = this.schema.parent(blueprint);
                result = result || this.readonly(blueprint);
            }
            while (blueprint != null);
        }

        return result;
    }

    public required(blueprint: Blueprint): boolean
    {
        let result = false;

        if (instanceOfRequiredBlueprint(blueprint))
        {
            if (this.state.design)
            {
                result = blueprint.required != NeverChoice.Never;
            }
            else
            {
                switch (blueprint.required)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.requiredWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        return result;
    }

    public customError(blueprint: CustomErrorBlueprint): boolean
    {
        if (instanceOfCustomErrorBlueprint(blueprint) && blueprint.customError == WhenChoice.When)
        {
            return this.executeExpression(blueprint.customErrorWhen);
        }

        return false;
    }

    public customErrorMessage(blueprint: CustomErrorBlueprint): string
    {
        if (instanceOfCustomErrorBlueprint(blueprint) && blueprint.customError == WhenChoice.When)
        {
            return this.executeExpression("`" + blueprint.customErrorMessage + "`") || '[[[Podano nieprawidłowe dane]]]';
        }

        return null;
    }
}

export class BlueprintManagerProxy
{
    public constructor(manager: SchemaManager)
    {
        return new Proxy(manager, {
            get: (manager: any, property: string) =>
            {
                return (manager as SchemaManager).find(property);
            }
        });
    }
}
