<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, ChoiceType, ChoiceEntry, instanceOfChoiceEntry, ChoiceFieldTypes } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: ChoiceType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new ChoiceEntry(), instanceOfChoiceEntry) as ChoiceEntry);
const display = computed(() =>
{
    if (entry.value.values && entry.value.values.length == 1)
        return component.value.options.where(p => p.value == entry.value.value).select(p => p.text).first();

    if (entry.value.values && entry.value.values.length > 1)
        return $t('[[[Wybrane %0 na %1|||{0}|||{1}]]]', entry.value.values.length, component.value.options.length);

    return null;
});
const model = computed({
    get()
    {
        return multiple.value ? (entry.value.values ?? []) : entry.value.value;
    },
    set(value)
    {
        entry.value.values = Array.isArray(value) ? value : [value];
    }
});
const multiple = computed(() => component.value.fieldType == ChoiceFieldTypes.Checkbox);

const reset = (): void =>
{
    entry.value.values = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-form-select name="status" v-model="model" :options="component.options" :size="Math.min(10, component.options.length)" :multiple="multiple"></ideo-form-select>
    </ideo-filter>
</template>
