<template>
    <responsive :top="true" v-bind="attrs()">
        <table class="table table-data table-stripped table-hover mb-0">
            <colgroup>
                <col v-for="(header, index) in headers" :key="`col-${index}`" :style="getStyle(header)">
            </colgroup>
            <thead v-if="showHeader">
                <tr>
                    <th v-for="(header, index) in headers" :key="`th-${index}`" :class="{'buttons': header.type == 'buttons' || false}">
                        <ideo-form-checkbox
                            v-model="allCheckbox"
                            @change="checkAllItems"
                            v-if="header.checkbox"
                        ></ideo-form-checkbox>
                        <a href="" class="text-wrap" @click.stop.prevent="onSorting(header)" v-else-if="header.sort || false">
                            <i :class="getIcon(header)"></i>
                            {{ header.title || '' }}
                        </a>
                        <span v-else class="text-wrap">
                            {{ header.title || '' }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <list-view-utils-row v-for="(item, index) in rows" :key="`row-${index}`" :index="index" :item="item" :headers="props.headers">
                    <slot name="row" :item="item" :index="index"></slot>
                </list-view-utils-row>
            </tbody>
            <tbody v-else>
                <td :colspan="headers.length" class="px-0">
                    <div class="text-center p-3 mt-1 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
                </td>
            </tbody>
        </table>
    </responsive>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, ref } from 'vue';
import Pager from '@/helpers/Pager';
import Row from '../utils/Row.vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

export interface Props
{
    showHeader: boolean;
    headers: Header[];
    rows: any[];
    pager: Pager;
}

defineOptions({
    name: 'list-view-table-layout',
    inheritAttrs: false,
    components: {
        'list-view-utils-row': Row
    }
});

const props = defineProps({
  "showHeader": { type: Boolean,  },
  "headers": null,
  "rows": null,
  "pager": null
});
const emptyLabel = inject<string>('empty-label');
const emit = defineEmits(["change", "check"]);

const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
const headers = computed(() =>
{
    return props.headers
        .where(p => p.visible !== false)
        .orderBy(p => p.position)
        .toArray();
});

const getStyle = (header: any): Record<string, string> =>
{
    return {
        'width': header.width ? `${header.width || 0}${(header.width || 0).toString().endsWith('%') ? '' : 'px'}` : ''
    };
};

const getIcon = (header: any): string =>
{
    return "fa fa-fw fa-sort" + (props.pager.sorting !== (header.sort || '') ? '' : props.pager.order.toLowerCase() === 'asc' ? '-up' : '-down');
};

const onSorting = (header: any): any =>
{
    emit('change', header);
};

const allCheckbox = ref(false);

const checkAllItems = (value: boolean): void =>
{
    emit('check', value);
};
</script>
