<script lang="ts" setup>
import { computed } from 'vue';
import { Definition, RichTextType, RichTextEntry, instanceOfRichTextEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: RichTextType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new RichTextEntry(), instanceOfRichTextEntry) as RichTextEntry);
const display = computed(() => entry.value.value);

const reset = (): void =>
{
    entry.value.value = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-input-group>
            <ideo-form-input v-model="entry.value" type="text" :name="component.name" v-focus></ideo-form-input>
            <template #append>
                <ideo-button icon="fas fa-fw fa-times" @click="reset()"></ideo-button>
            </template>
        </ideo-input-group>
    </ideo-filter>
</template>
