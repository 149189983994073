// Blueprints
import { Blueprint, BlueprintConstructor } from '../blueprints/Blueprint';
import { DocumentBlueprint, DocumentConstructor } from '../blueprints/DocumentBlueprint';
import { AreaBlueprint, AreaConstructor } from '../blueprints/AreaBlueprint';
import { SlotBlueprint, SlotConstructor } from '../blueprints/SlotBlueprint';

// --------------------------------------------------

export class BlueprintFactory
{
    private documentConstructor: DocumentConstructor;
    private areaConstructor: AreaConstructor;
    private slotConstructor: SlotConstructor;
    private types: Record<string, BlueprintConstructor>;

    public constructor(
        documentConstructor: DocumentConstructor,
        areaConstructor: AreaConstructor,
        slotConstructor: SlotConstructor,
        types: Record<string, BlueprintConstructor>)
    {
        this.documentConstructor = documentConstructor;
        this.areaConstructor = areaConstructor;
        this.slotConstructor = slotConstructor;
        this.types = types;
    }

    public createDocument(id: string, name: string): DocumentBlueprint
    {
        return new this.documentConstructor(id, name);
    }

    public createArea(id: string, name: string): AreaBlueprint
    {
        return new this.areaConstructor(id, name);
    }

    public createSlot(id: string, name: string): SlotBlueprint
    {
        return new this.slotConstructor(id, name);
    }

    public create(type:string, id: string, name: string, width: number): Blueprint
    {
        return new this.types[type](id, name, width);
    }
}
