import { Blueprint } from "./Blueprint";
import { AggregateBlueprint, instanceOfAggregateBlueprint } from "./AggregateBlueprint";

export interface AreaBlueprint extends AggregateBlueprint
{
    kind: 'area';
}

export interface AreaConstructor
{
    new (id: string, name: string): AreaBlueprint;
}

export const instanceOfAreaBlueprint = (object: any): object is AreaBlueprint =>
{
    return instanceOfAggregateBlueprint(object) && 'kind' in object && object.kind === 'area';
};

export abstract class AreaAbstract implements AreaBlueprint
{
    public kind: 'area' = 'area' as const;
    public id: string;
    public type: string;
    public name: string;
    public disabled?: boolean = false;
    public components: Blueprint[] = [];

    public constructor(id: string, name: string, type: string)
    {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}
