import transform from 'lodash/transform';
import isObject from 'lodash/isObject';
import trimEnd from 'lodash/trimEnd';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import sha256 from 'crypto-js/sha256';

export const formatFileSize = (size: number): string =>
{
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const threshold = 1024;

    size = Number(size) * threshold;

    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold));

    size = size / Math.pow(threshold, i);

    return `${size.toFixed(2)} ${units[i]}`;
};

export const polishPlurals = (singularNominativ: string, pluralNominativ: string, pluralGenitive: string, value: number): string =>
{
    value = Math.abs(value);

    if (value === 1)
        return singularNominativ;
    else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20))
        return pluralNominativ;
    else
        return pluralGenitive;
};

export const deepMap = (data: any, callback: (data: any) => any, policy: (data: any) => boolean = (value: any) => true): any => transform(data, (result: any, value: any, key: string|number): any =>
{
    result[key] = policy(value) && isObject(value) ? deepMap(value, callback, policy) : callback(value);

    return result;
});

export const formatDateTime = (input: DateTime | Date | string, format: string = "yyyy-MM-dd HH:mm:ss", zone: string = null): string =>
{
    if (input && format)
    {
        let value = cloneDeep(input);
        const zoneName = zone || DateTime.local().zoneName;

        if (value instanceof Date)
            value = DateTime.fromJSDate(value);

        if (typeof value === "string")
            value = DateTime.fromISO(value);

        if (value instanceof DateTime)
            return value.setZone(zoneName).toFormat(format);
    }

    return "-";
};

export const requestToken = (input: string, salt: string): string =>
{
    return sha256(input + '#' + salt).toString();
};

export const baseurl = (value: string = ''): string =>
{
    return `${trimEnd(import.meta.env.VITE_APP_ADMIN_URL, '/')}/${value}`;
};

const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(\.\d{3})?)Z/;

export const convertDates = (result: any): any =>
{
    if (result && isObject(result))
    {
        result = deepMap(result, function(value)
        {
            if (typeof value === 'string' && (value.length == 20 || value.length == 24) && ISO_8601.test(value))
            {
                return DateTime.fromISO(value).setZone('UTC');
            }

            return value;
        });
    }

    return result;
};

export const datesToString = (result: any): any =>
{
    if (result && isObject(result))
    {
        result = deepMap(result,
            (value) =>
            {
                if (DateTime.isDateTime(value))
                {
                    return value.toString();
                }

                return value;
            },
            (value) =>
            {
                return !DateTime.isDateTime(value);
            }
        );
    }

    return result;
};

export const wait = async (valueFactory: () => any): Promise<any> =>
{
    return await new Promise((resolve, reject) =>
    {
        const internal = (): void =>
        {
            const value = valueFactory();

            if (value)
                return resolve(value);

            setTimeout(internal, 1000);
        };

        internal();
    });
};

export const randomNumber = (min: number = 1, max: number = 10000000): number =>
{
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const sleep = (ms: number = 1000): Promise<void> =>
{
    return new Promise((resolve) =>
    {
        setInterval(() => resolve(), ms);
    });
};

export const dump = (...params: any[]): void =>
{
    // eslint-disable-next-line no-console
    console.log('DUMP', ...params.map(p => JSON.parse(JSON.stringify(p))));
};

export const isProxy = (item: any): boolean =>
{
    return item && isObject(item) && '__v_isRef' in item;
};

export const only = (item: Record<string, any>, ...properties: string[]): Record<string, any> =>
{
    const result = {};

    Object.keys(item).forEach(key =>
    {
        if (properties.includes(key))
        {
            result[key] = item[key];
        }
    });

    return result;
};

export const except = (item: Record<string, any>, ...properties: string[]): Record<string, any> =>
{
    const result = {};

    Object.keys(item).forEach(key =>
    {
        if (!properties.includes(key))
        {
            result[key] = item[key];
        }
    });

    return result;
};

export const normalizeClasses = (classes: string | string[] | Record<string, boolean>) : Record<string, boolean> =>
{
    if (classes == null || classes == undefined)
    {
        return {};
    }

    if (Array.isArray(classes))
    {
        return classes.reduce((o, key) => ({ ...o, [key]: true }), {});
    }

    if (typeof classes == 'string')
    {
        return {
            [classes]: true
        };
    }

    return classes;
};
