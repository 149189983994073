import * as Vue from 'vue';
import { loadModule } from 'vue3-sfc-loader';

export function parseToComponent(id: string, sfcString: string)
{
    const options = {
        moduleCache: { vue: Vue },
        async getFile(url: string)
        {
            return Promise.resolve(sfcString);
        },
        addStyle(styleString: string)
        {
            let style = document.getElementById(id);

            if (!style)
            {
                style = document.createElement('style');
                style.setAttribute('id', id);

                const ref = document.head.getElementsByTagName('style')[0] || null;

                document.head.insertBefore(style, ref);
            }

            style.textContent = styleString;
        }
    };
    const component = loadModule(`${id}.vue`, options);

    return Vue.defineAsyncComponent(() => component);
}
