import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';

export const Definition: BlueprintDefinition = {
    type: 'related-children',
    name: '[[[Dokumenty]]]',
    icon: 'fa-table',
    group: 'relational',
    position: 3,
    disabled: false
};

export interface RelatedChildrenContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, HasLabel, HasHelp, HasWidth
{
    categoryId: number;
    formId: number;
}

export class RelatedChildrenType implements RelatedChildrenContract, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public categoryId: number;
    public formId: number;
    public help: string;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public errors: ValidationErrors;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = '[[[Dokumenty]]]';
        this.showLabel = true;
        this.categoryId = null;
        this.formId = null;
        this.help = '';
        this.minWidth = 6;
        this.width = 6;
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.categoryId)
            this.errors.categoryId = ['[[[Wymagane jest wybranie kategorii]]]'];

        if (!this.formId)
            this.errors.formId = ['[[[Wymagane jest wybranie formularza]]]'];

        return {
            [this.name]: this.errors
        };
    }
}
