<template>
    <div class="select-container" :style="getStyle()">
        <select
            v-model="innerValue"
            ref="select"
            class="form-select"
            :class="{'focused shadow': focused, 'flip': focused && flip, 'single': isSingle()}"
            :multiple="multiple"
            :disabled="disabled"
            :size="focused ? dynamicSize : size"
            @focus="onFocus()"
            @blur="onBlur()"
        >
            <slot name="first"></slot>
            <slot name="default">
                <ideo-form-select-option v-for="(option, i) in options" :value="optionValue(option)" :key="i" :disabled="isDisabled(option)">
                    {{ option[textField] }}
                </ideo-form-select-option>
            </slot>
        </select>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from '@/helpers/Decorators';
import IdeoFormSelectOption from './IdeoFormSelectOption.vue';

@Options({
    name: 'ideo-form-select',
    components: {
        'ideo-form-select-option': IdeoFormSelectOption
    }
})
export default class IdeoFormSelect extends Vue
{
    public currentValue: any = null;
    public currentSize: number = 1;
    public clientWidth: number = 100;
    public focus: boolean = false;
    public flip: boolean = false;

    @Ref()
    public select: () => HTMLSelectElement;

    @Prop()
    public modelValue: any;

    @Prop({ default: 'value' })
    public valueField: string;

    @Prop({ default: 'text' })
    public textField: string;

    @Prop({ default: 'disabled' })
    public disabledField: string;

    @Prop({ default: (): any => [] })
    public options: Record<string, any>[];

    @Prop({ default: false })
    public multiple: boolean;

    @Prop({ default: 1 })
    public size: number;

    @Prop({ default: false })
    public disabled: boolean;

    public get innerValue(): any
    {
        return this.modelValue;
    }

    public set innerValue(value: any)
    {
        this.$emit('update:modelValue', value);
        this.select().blur();
        this.currentSize = 1;
    }

    public get focused(): boolean
    {
        return this.multiple == false && this.size == 1 && this.focus;
    }

    public get dynamicSize(): number
    {
        return Math.max(2, Math.min(this.select().options.length, 10));
    }

    public isDisabled(option: any): boolean
    {
        if (this.disabledField in option)
        {
            return option[this.disabledField];
        }

        return false;
    }

    public optionValue(option: Record<string, any>): any
    {
        return option[this.valueField];
    }

    public onFocus(): void
    {
        this.clientWidth = this.select().offsetWidth;
        this.focus = true;

        this.$nextTick(() =>
        {
            const docRect = document.documentElement.getBoundingClientRect();
            const selectRect = this.select().getBoundingClientRect();

            this.flip = (docRect.height - (selectRect.top + selectRect.height)) <= 0;
        });
    }

    public onBlur(): void
    {
        this.clientWidth = 0;
        this.focus = false;
        this.flip = false;
    }

    public getStyle(): any
    {
        if (this.focus)
            return {'min-width': `${this.clientWidth}px`};
        else
            return {};
    }

    public isSingle(): boolean
    {
        return this.$refs.select ? this.select().options.length === 1 : false;
    }
}
</script>

<style lang="scss" scoped>
.select-container {
    position: relative;
    min-height: 33px;

    select {
        &.focused {
            position: absolute;
            z-index: 5;
        }
        &.flip {
            bottom: 0;
        }
    }
}
</style>
