import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, EntityVersion } from '@/helpers/Interfaces';
import { PublicationEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormContract, FormType } from '@/components/forms/blueprints/form';

export default class FormsService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/forms', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/forms/${id}`)).data;
    }

    public static async getOptions(pager: Pager, categoryId: number | null, id: number | null, search: string = null): Promise<Pagination<FormOptionModel>>
    {
        return (await axios.get<Pagination<FormOptionModel>>('admin/forms/options', {
            params: merge({}, pager.data(), { category: categoryId, id: id, search: search })
        })).data;
    }

    public static async fetchDefinition(versionId: number): Promise<FormType>
    {
        return (await axios.get<FormType>(`admin/forms/definition/${versionId}`)).data;
    }

    public static async fetchPublished(originId: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/forms/definition/${originId}/published`)).data;
    }

    public static async create(model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/forms`, model)).data;
    }

    public static async update(id: number, model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/forms/${id}`, model)).data;
    }

    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/forms/${id}`)).data as Statement;
    }

    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/forms/${id}/status`, {
            status: status
        })).data;
    }

    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/forms/${id}/versions`, {
            params: pager.data()
        })).data;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    category: string;
    status: PublicationEnum;
    isArchived: boolean;
    ownerId: number;
    resourceId: number;
}

export interface FilterModel
{
    categoryId?: number;
    userId: number;
    status?: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    showArchived: boolean;
    onlyMine: boolean;
}

export interface UpsertModel
{
    id: number;
    definition: FormContract;
    isVersionable: boolean;
    isArchived: boolean;
    allowAnonymous: boolean;
}

export interface FormModel extends UpsertModel
{
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
}

export interface FormOptionModel
{
    value: number;
    text: string;
    isDisabled: boolean;
}
