import { SchemaManager } from '../../base/managers/SchemaManager';

export class DomManager
{
    private schema: SchemaManager;
    private top: number = 0;
    private offset: number = 0;
    private height: number = 0;
    private toolbox: [number, number] = [0, 0];

    public constructor(schemaManager: SchemaManager)
    {
        this.schema = schemaManager;
    }

    public offsetHeight(height: number): void
    {
        this.height = height;
    }

    public getOffsetHeight(): number
    {
        return this.height;
    }

    public offsetTop(element: any): void
    {
        const el = element.$el || element.vnode?.el;
        const root = this.schema.designer(this.schema.getBlueprint().type);
        let offset = el?.getBoundingClientRect ? el.getBoundingClientRect().top : 0;

        do
        {
            if ((element.$options || element.type).name == root)
            {
                this.top = el?.getBoundingClientRect ? el.getBoundingClientRect().top : 0;
                offset -= this.top;

                element = element.$parent || element.parent;
            }
            else
            {
                break;
            }
        }
        while (element.$parent || element.parent);

        this.offset = offset;
    }

    public getOffsetTop(): number
    {
        return this.offset;
    }

    public getDocumentTop(): number
    {
        return this.top;
    }

    public setCurrentOffset(offset: number, top: number): [number, number]
    {
        return (this.toolbox = [offset, top]);
    }

    public getCurrentOffset(): [number, number]
    {
        return this.toolbox;
    }
}
