import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement, KeyValue } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { AccountType } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormEntry } from '@/components/forms/blueprints/form';

export default class ReportsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ReportsListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ReportsListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ReportsListItemModel>>>('reports', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     *
     * @returns Promise<Blob>
     */
    public static async getLogs(): Promise<Blob>
    {
        const response = await axios.get('reports/getLogs', { responseType: 'blob' });

        return response.data;
    }
}

export interface ReportsListItemModel
{
    id: number;
    shopName: string;
    productName: string;
    url: string;
    internalNumber: string;
    internalProducer: string;
    priceBeforeChanges: number;
    priceAfterChanges: number;
    priceDifference: number;
    availabilityBeforeChanges: string;
    availabilityAfterChanges: string;
}

export interface FilterModel
{
    shopName: string;
    dateTimeFromUtc?: DateTime;
    dateTimeDueUtc?: DateTime;
    priceIncreased: boolean;
    priceDecreased: boolean;
    availabilityChanged : boolean;
    groupId: number | null;
}
