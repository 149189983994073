<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, NumericType, NumericEntry, instanceOfNumericEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: NumericType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new NumericEntry(), instanceOfNumericEntry) as NumericEntry);
const display = computed(() =>
{
    let text = "";

    if (entry.value.min)
        text += $t(`[[[od %0|||${entry.value.min}]]] `);

    if (entry.value.max)
        text += $t(` [[[do %0|||${entry.value.max}]]]`);

    return text.trim().length > 0 ? text : null;
});

const resetMin = (): void =>
{
    entry.value.min = null;
};
const resetMax = (): void =>
{
    entry.value.max = null;
};
const reset = (): void =>
{
    resetMin();
    resetMax();
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="mb-n2">
            <div class="mb-2">
                <ideo-input-group>
                    <ideo-form-input v-model="entry.min" type="number" :name="component.name" :min="component.range.min" :max="component.range.max" :step="component.step" :placeholder="$t('[[[Od]]]')" />
                    <template #append>
                        <ideo-button icon="fas fa-fw fa-times" @click="resetMin()"></ideo-button>
                    </template>
                </ideo-input-group>
            </div>
            <div class="mb-2">
                <ideo-input-group>
                    <ideo-form-input v-model="entry.max" type="number" :name="component.name" :min="component.range.min" :max="component.range.max" :step="component.step" :placeholder="$t('[[[Do]]]')" />
                    <template #append>
                        <ideo-button icon="fas fa-fw fa-times" @click="resetMax()"></ideo-button>
                    </template>
                </ideo-input-group>
            </div>
        </div>
    </ideo-filter>
</template>
