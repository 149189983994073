import { axios } from '@/plugins/axios';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, EntityVersion, Option } from '@/helpers/Interfaces';
import { PublicationEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormEntry } from '@/components/forms/blueprints/form';
import AbstractService from '@/helpers/AbstractService';

export default class EntriesService extends AbstractService
{
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.post<Pagination<Resource<ListItemModel>>>('admin/forms/entries/list', filter, { params: pager.data() })).data;
    }

    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/forms/entries/${id}`)).data;
    }

    public static async create(formOrigin: number, formId: number): Promise<{id: number, cohesionId?: string}>
    {
        const { data: { result: { id, cohesionId } } } = await axios.post<Resource<FormModel>>(`admin/forms/entries`, { formOrigin, formId });

        return { id, cohesionId };
    }

    public static async update(id: number, model: UpsertModel, document: any): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/forms/entries/${id}`, {...model, ...{ document }})).data;
    }

    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/forms/entries/${id}`)).data as Statement;
    }

    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/forms/entries/${id}/status`, {
            status: status
        })).data;
    }

    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/forms/entries/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    public static async getOptions(pager: Pager, formId: number, scope: number[], id: number | null, search: string = null): Promise<Pagination<Option<number>>>
    {
        return (await axios.get<Pagination<Option<number>>>(`admin/forms/${formId}/entries/options`, {
            params: {...pager.data(), scope, id, search }
        })).data;
    }

    public static async getOptionsPublished(pager: Pager, formId: number, scope: number[], id: number | null, search: string = null): Promise<Pagination<Option<number>>>
    {
        return (await axios.get<Pagination<Option<number>>>(`admin/forms/${formId}/entries/options/published`, {
            params: {...pager.data(), scope, id, search }
        })).data;
    }

    public static async exportToExcel(formId: number): Promise<any>
    {
        return (await this.$getFile(`admin/forms/${formId}/entries/export/excel`, 'Raport formularza.xlsx'));
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
    document: Record<string, string>;
}

export interface FilterModel
{
    formId?: number;
    userId: number;
    status?: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    document: FormEntry;
    relatedTo: number[];
}

export interface UpsertModel
{
    id: number;
    formOrigin: number;
    formId: number;
    cohesionId?: string;
    document: FormEntry;
}

export interface FormModel extends UpsertModel
{
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
}
