export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2023-01-24T09:45:20.429Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2023-01-24T09:45:20.524Z",
    "Language": "pl-PL",
    "Messages": {
        "%0 znaków": "",
        "1 godzina": "",
        "10 minut": "",
        "12 godzin": "",
        "15 minut": "",
        "24 godziny": "",
        "3 godziny": "",
        "30 minut": "",
        "5 minut": "",
        "6 godzin": "",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
        "Administracja": "",
        "Administracja - Kraje": "",
        "Administracja - Miasta": "",
        "Administracja - Role": "",
        "Administracja - Stanowiska pracy": "",
        "Administracja - Użytkownicy": "",
        "Administracja - Województwa": "",
        "Administracja - Zespoły": "",
        "Administratorzy": "",
        "Adres": "",
        "Adres e-mail potwierdzony": "",
        "Adres email": "",
        "Adres email nadawcy": "",
        "Adres IP": "",
        "Adres serwera": "",
        "Adres URL": "",
        "Akceptacja": "",
        "Akceptuj": "",
        "Akcja": "",
        "Akcje": "",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "",
        "Aktualne hasło": "",
        "Aktualne urządzenie": "",
        "Aktywne": "",
        "Aktywne kanały komunikacji": "",
        "Angielski": "",
        "Ankieta": "",
        "Anuluj": "",
        "Autor": "",
        "Azure AD": "",
        "Bardzo dobrze": "",
        "Bardzo niezadowolony": "",
        "Bardzo zadowolony": "",
        "Bardzo źle": "",
        "Baza danych": "",
        "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "",
        "Bazy danych": "",
        "Bezpieczeństwo": "",
        "Bezpieczne połączenie SSL": "",
        "Blokada konta": "",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "",
        "Błędne": "",
        "Brak": "",
        "Brak aktywnego filtru": "",
        "Brak działu nadrzędnego": "",
        "Brak kluczy": "",
        "Brak nowych powiadomień.": "",
        "Brak odpowiedzi": "",
        "Brak wersji": "",
        "Brak wyników": "",
        "Checkbox": "",
        "Classic mode": "",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "",
        "Co 3 miesiące": "",
        "co 30 dni": "",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "",
        "Co miesiąc": "",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "",
        "Co tydzień": "",
        "Codziennie": "",
        "Coś poszło nie tak": "",
        "Cron": "",
        "Cyfry": "",
        "Czas": "",
        "Czas pomiędzy": "",
        "Czas trwania blokady": "",
        "Czas uruchomienia": "",
        "Czerwiec": "",
        "Członkowie zespołu": "",
        "Cztery kolumny": "",
        "Dalej": "",
        "Dane formularza są przechowywane w obiekcie Entry.": "",
        "Dane osobowe": "",
        "Dark mode": "",
        "Data": "",
        "Data do": "",
        "Data dodania": "",
        "Data i czas": "",
        "Data modyfikacji": "",
        "Data od": "",
        "Data opuszczenia zespołu": "",
        "Data rejestracji": "",
        "Data rozpoczęcia": "",
        "Data uruchomienia": "",
        "Data usunięcia": "",
        "Data utworzenia": "",
        "Data utworzenia wersji": "",
        "Data użycia": "",
        "Data wygaśnięcia": "",
        "Data wylogowania": "",
        "Data zakończenia": "",
        "Data zakończenia blokady konta": "",
        "Data zalogowania": "",
        "Data zdarzenia": "",
        "Default mode": "",
        "Definicja formularza znajduje się w obiekcie Form.": "",
        "Dni": "",
        "do": "",
        "Do akceptacji": "",
        "do czasu ręcznego odblokowania": "",
        "Dobrze": "",
        "Dodaj": "",
        "Dodaj dni": "",
        "Dodaj do zespołu": "",
        "Dodaj dział": "",
        "Dodaj formularz": "",
        "Dodaj kategorię": "",
        "Dodaj klienta": "",
        "Dodaj rolę": "",
        "Dodaj serwer": "",
        "Dodaj shortcode": "",
        "Dodaj stronę": "",
        "Dodaj szablon": "",
        "Dodaj użytkownika": "",
        "Dodaj wpis": "",
        "Dodaj zastępstwo": "",
        "Dodaj zespół": "",
        "Dodaj źródło": "",
        "dodane i edytowane przeze mnie": "",
        "Dodane przeze mnie": "",
        "Dodanie": "",
        "Dodanie działu": "",
        "Dodanie formularza": "",
        "Dodanie klienta": "",
        "Dodanie roli": "",
        "Dodanie serwera": "",
        "Dodanie użytkownika": "",
        "Dodanie zastępstwa": "",
        "Dodanie zespołu": "",
        "Dodanie źródła danych": "",
        "Dodanych plików %0 (limit wynosi %1).": "",
        "Dodawanie": "",
        "Dokument %0": "",
        "Domyślna wartość": "",
        "Dopuść brak odpowiedzi": "",
        "Dostęp niezdefiniowany": "",
        "Dostęp przyznany": "",
        "Dostęp zabroniony": "",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
        "Dowolny": "",
        "Dozwolone rozszerzenia": "",
        "Drugie imię": "",
        "Dwie kolumny": "",
        "Dział aktywny": "",
        "Dział nadrzędny": "",
        "Dział został dodany.": "",
        "Dział został zaktualizowany.": "",
        "Dzielenie": "",
        "E-mail": "",
        "Edycja działu": "",
        "Edycja formularza": "",
        "Edycja klienta": "",
        "Edycja roli": "",
        "Edycja serwera": "",
        "Edycja użytkownika": "",
        "Edycja zastępstwa": "",
        "Edycja zespołu": "",
        "Edycja źródła danych": "",
        "Edytowana wersja": "",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
        "Edytuj": "",
        "Edytuj dział": "",
        "Edytuj obraz": "",
        "Edytuj szablon": "",
        "Edytuj uprawnienia": "",
        "Edytuj wpis": "",
        "Element został przywrócony.": "",
        "Element został usunięty.": "",
        "Email": "",
        "Etykieta": "",
        "Etykiety": "",
        "Filtr globalny": "",
        "Filtr prywatny": "",
        "Filtr został usunięty": "",
        "Filtr został zapisany": "",
        "Filtruj": "",
        "Filtry": "",
        "Filtry globalne": "",
        "Filtry systemowe": "",
        "Formularz": "",
        "Formularz zarchiwizowany": "",
        "Formularz został dodany.": "",
        "Formularz został zaktualizowany.": "",
        "Formularze": "",
        "Godziny": "",
        "Grudzień": "",
        "Grupa": "",
        "Harmonogram zadań": "",
        "Harmonogram został zmieniony": "",
        "Hasło": "",
        "Hasło (Klucz ukryty)": "",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
        "Hasło użytkownika serwisowego": "",
        "Hasło zostało zmienione. Zaloguj się ponownie": "",
        "Historia logowania": "",
        "Historia zadania": "",
        "Host": "",
        "Host dodatkowy": "",
        "Host główny": "",
        "Id": "",
        "ID": "",
        "ID lub typ zadania": "",
        "Id modelu: %0": "",
        "ID obiektu": "",
        "Id sesji": "",
        "ID zadania": "",
        "Id zdarzenia": "",
        "Identyfikator zadania": "",
        "Ilość wierszy": "",
        "Ilość wyników do odrzucenia": "",
        "Ilość wyników do pobrania": "",
        "Ilość znaków": "",
        "Imię": "",
        "Imię i Nazwisko": "",
        "Indywidualne tokeny": "",
        "Inspekcja": "",
        "Jedna kolumna": "",
        "Kalendarz - Kategorie zasobów": "",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
        "kanał wymagany": "",
        "Kanały komunikacji": "",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "",
        "Kanały włączone w ustawieniach aplikacji": "",
        "Katalog": "",
        "Katalog został utworzony.": "",
        "Kategoria": "",
        "Kategoria jest wymagana": "",
        "Kategoria została dodana.": "",
        "Kategoria została usunięta.": "",
        "Kategoria została zaktualizowana.": "",
        "Kategorie": "",
        "Klienci": "",
        "Klient aktywny": "",
        "Klient został dodany.": "",
        "Klient został usunięty.": "",
        "Klient został zaktualizowany.": "",
        "Klucze": "",
        "Kod pocztowy": "",
        "Kolejka": "",
        "Komunikat": "",
        "Komunikat błędu": "",
        "Komunikat walidacji": "",
        "Konfiguracja domyślna": "",
        "Konfiguracja harmonogramu": "",
        "Kontakt": "",
        "Kontakty - Typ działania": "",
        "Kontener plików": "",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Kontrolka wyboru (pojedynczy wybór)": "",
        "Kontrolka wyboru (wybór wielokrotny)": "",
        "Kontrolki podstawowe": "",
        "Kontrolki specjalizowane": "",
        "Kopiuj": "",
        "Kopiuj filtr": "",
        "Kosz": "",
        "Kraj": "",
        "Kroki": "",
        "Kwiecień": "",
        "Lewy panel": "",
        "Liczba": "",
        "Liczba całkowita": "",
        "Liczba dziesiętna": "",
        "Linia": "",
        "linie": "",
        "Lipiec": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
        "Lista jest pusta": "",
        "Lista plików": "",
        "Lista powiadomień": "",
        "Lista rozwijana": "",
        "Lista urządzeń na których jesteś zalogowany": "",
        "Listopad": "",
        "Litery": "",
        "Litery i cyfry": "",
        "Login": "",
        "Logo": "",
        "Logowanie": "",
        "Logowanie zakończyło się niepowodzeniem.": "",
        "Luty": "",
        "Łączenie treści": "",
        "Maj": "",
        "Maksimum": "",
        "Maksymalna ilość plików": "",
        "Maksymalny rozmiar pliku w MB": "",
        "Małe znaki": "",
        "Marzec": "",
        "Miejsca po przecinku": "",
        "Miejsce logowania": "",
        "Miejscowość": "",
        "Miesiąc": "",
        "Miesiące": "",
        "Migracje": "",
        "Miniaturki": "",
        "Minimalna długość hasła": "",
        "Minimum": "",
        "Minuty": "",
        "Mnożenie": "",
        "Modyfikacja": "",
        "Moje filtry": "",
        "Moje konto": "",
        "Monitor kolejek": "",
        "Monitoring": "",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Mój tytuł": "",
        "Na pewno przenieść wybrane elementy do bieżącego katalogu?": "",
        "Na pewno usunąć wybrane elementy?": "",
        "Nadawca": "",
        "Narzędzia": "",
        "Następna strona": "",
        "Nazwa": "",
        "Nazwa bazy danych": "",
        "Nazwa działu": "",
        "Nazwa katalogu": "",
        "Nazwa katalogu lub pliku": "",
        "Nazwa katalogu została zmieniona.": "",
        "Nazwa kategorii": "",
        "Nazwa klienta": "",
        "Nazwa kolumny z opisem": "",
        "Nazwa kolumny z wartością": "",
        "Nazwa konta (Klucz dostępu)": "",
        "Nazwa nadawcy": "",
        "Nazwa obiektu": "",
        "Nazwa pliku": "",
        "Nazwa pliku została zmieniona.": "",
        "Nazwa pola": "",
        "Nazwa przycisku ``Dalej``": "",
        "Nazwa przycisku ``Wstecz``": "",
        "Nazwa przycisku ``Wyślij``": "",
        "Nazwa roli": "",
        "Nazwa serwera": "",
        "Nazwa szablonu": "",
        "Nazwa użytkownika": "",
        "Nazwa użytkownika serwisowego": "",
        "Nazwa zadania": "",
        "Nazwa zespołu": "",
        "Nazwa źródła": "",
        "Nazwa źródła danych": "",
        "Nazwisko": "",
        "Neutralne": "",
        "Nie": "",
        "Nie mam zdania": "",
        "Nie masz uprawnień do panelu administracyjnego.": "",
        "Nie masz wymaganych uprawnień do wykonania wybranej operacji.": "",
        "Nie możesz modyfikować uprawnień": "",
        "Nie można dodać pliku(ów): \"%0\". Można dodawać tylko pliki typu: \"%1\".": "",
        "Nie można usunąć swojego konta.": "",
        "Nie podano wymaganej ilości znaków: %0.": "",
        "Nie udało się pobrać listy działów": "",
        "Nie udało się pobrać listy filtrów": "",
        "Nie udało się pobrać listy kanałów": "",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy powiadomień": "",
        "Nie udało się pobrać listy ról": "",
        "Nie udało się pobrać listy serwerów baz danych": "",
        "Nie udało się pobrać listy serwerów plików": "",
        "Nie udało się pobrać listy typów modeli": "",
        "Nie udało się pobrać listy wydziałów": "",
        "Nie udało się pobrać listy zastępstw": "",
        "Nie udało się pobrać powiadomień.": "",
        "Nie udało się usunąć szablonu": "",
        "Nie udało się wysłać pliku": "",
        "Nie udało się zapisać szablonu": "",
        "Nie wiem": "",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
        "nie wymuszaj zmiany hasła": "",
        "Nie zdefiniowano żadnych ról.": "",
        "Nie znaleziono żadnych plików": "",
        "Nie znaleziono żadnych wyników.": "",
        "Niepotwierdzeni": "",
        "Nieprawidłowy adres email": "",
        "Nieprawidłowy adres URL": "",
        "Niestandardowy błąd": "",
        "Nieudane": "",
        "Nieudane próby logowania": "",
        "Nieważne": "",
        "Niewidoczne": "",
        "Niezadowolony": "",
        "Nigdy": "",
        "Nikomu nie przydzielono uprawnień": "",
        "Nowa data": "",
        "Nowe hasło": "",
        "Numer domu": "",
        "Numer mieszkania": "",
        "Numer sesji": "",
        "Numer telefonu": "",
        "Obojętny": "",
        "Obok siebie": "",
        "Obraz został wycięty.": "",
        "Oczekuje na wysłanie": "",
        "od": "",
        "Odejmowanie": "",
        "Odepnij menu": "",
        "Odpowiedzi": "",
        "Odpowiedź 1": "",
        "Odpowiedź 2": "",
        "Odpowiedź 3": "",
        "Odpowiedź 4": "",
        "Odpowiedź 5": "",
        "Odrzucone": "",
        "Odstęp": "",
        "Odśwież": "",
        "Odznacz wszystkie": "",
        "Ok": "",
        "Opcje": "",
        "Opis": "",
        "Opis zespołu": "",
        "Opis źródła": "",
        "Opisy": "",
        "Oraz nowe hasło": "",
        "Organizacja": "",
        "Osoba zastępowana": "",
        "Osoba zastępująca": "",
        "Ostatnia aktywność": "",
        "Ostatnia strona": "",
        "Parametry, wymagane do wykonania zapytania:": "",
        "Pasek": "",
        "Pasek postępu": "",
        "Październik": "",
        "Pierwsza strona": "",
        "Plik jest wysyłany": "",
        "Plik został wysłany": "",
        "Pliki do wysłania: %0": "",
        "Pliki prywatne": "",
        "Pliki publiczne": "",
        "po %0 nieudanych próbach logowania": "",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
        "Pobierz plik": "",
        "Poczta": "",
        "Podaj swój adres email": "",
        "Podana nazwa jest zajęta.": "",
        "Podana wartość jest nieprawidłowa.": "",
        "Podanie nazwy jest wymagane.": "",
        "Podano nieprawidłowe dane": "",
        "Podano nieprawidłowe dane.": "",
        "Podgląd": "",
        "Podpis": "",
        "Podstawowe operacje na datach": "",
        "Podstawowe operacje na liczbach": "",
        "Podstawowe operacje tekstowe": "",
        "Podstawowe operacje warunkowe": "",
        "Podziedziałek - Piątek": "",
        "Pojedynczy wybór": "",
        "Pokaż etykietę": "",
        "Pokaż filtry": "",
        "Pokaż historię": "",
        "Pokaż numery stron": "",
        "Pokaż przycisk ``Wyślij``": "",
        "Pokaż szczegóły": "",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "",
        "Pokaż tytuł sekcji": "",
        "Pokaż tytuł strony": "",
        "Pokaż tytuły stron": "",
        "Pokaż więcej": "",
        "pokaż więcej...": "",
        "Pole \"%0\" jest wymagane.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
        "Pole \"Drugie imię\" jest wymagane.": "",
        "Pole \"Imię\" jest wymagane.": "",
        "Pole \"Nazwisko\" jest wymagane.": "",
        "Pole \"Tytuł\" jest wymagane.": "",
        "Pole może zawierać jedynie cyfry.": "",
        "Pole może zawierać jedynie litery i cyfry.": "",
        "Pole może zawierać jedynie litery.": "",
        "Pole wymagane": "",
        "Pole zawiera": "",
        "Polski": "",
        "Pomoc": "",
        "Poprawne": "",
        "Poprzednia strona": "",
        "Porównaj %0": "",
        "Porównaj z edytowaną wersją": "",
        "Porównania": "",
        "Porównanie": "",
        "Porównywana wersja": "",
        "Port": "",
        "Posiada załączniki": "",
        "Potwierdzenie przywrócenia": "",
        "Potwierdzenie usunięcia": "",
        "Potwierdzenie zmiany statusu": "",
        "Potwierdź akceptację": "",
        "Powiadomienia": "",
        "Powiadomienia zostały skonfigurowane.": "",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiększ": "",
        "Powrót": "",
        "Powtórz hasło": "",
        "Powtórzone": "",
        "Pozostało %0 znaków.": "",
        "Pozycja": "",
        "Prawy panel": "",
        "Procent": "",
        "Profil użytkownika": "",
        "Profil został zaktualizowany.": "",
        "Projekt": "",
        "Prywatne": "",
        "Przeciągnij i upuść aby posortować pliki": "",
        "Przedrostek": "",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "",
        "Przekroczono dozwoloną ilość znaków: %0.": "",
        "Przełącznik": "",
        "Przeniesienie wybranych elementów": "",
        "Przenieś": "",
        "Przenieś wybrane": "",
        "Przesuń do góry": "",
        "Przesuń na dół": "",
        "Przetestuj ustawienia poczty": "",
        "Przetestuj ustawienia Sms": "",
        "Przetwarzane": "",
        "Przybornik": "",
        "Przypnij menu": "",
        "Przyrostek": "",
        "Przywróć": "",
        "Przyznaj dostęp": "",
        "Publiczne": "",
        "Pytania": "",
        "Pytanie 1": "",
        "Pytanie 2": "",
        "Pytanie 3": "",
        "Raczej się nie zgadzam": "",
        "Raczej się zgadzam": "",
        "Radio": "",
        "Ranga": "",
        "Region": "",
        "Rejestr zmian": "",
        "Repozytorium plików": "",
        "Resetuj": "",
        "Rodzaj odpowiedzi": "",
        "Rok": "",
        "Rola": "",
        "Rola aktywna": "",
        "Rola została dodana.": "",
        "Rola została usunięta.": "",
        "Rola została zaktualizowana.": "",
        "Role i uprawnienia": "",
        "Role użytkownika": "",
        "Rozmiar": "",
        "Rozmiar zdjęcia został zmieniony.": "",
        "Równość": "",
        "Satysfakcja": "",
        "Sekcja": "",
        "Serwer bazy danych": "",
        "Serwer dostępny": "",
        "Serwer plików": "",
        "Serwer został dodany.": "",
        "Serwer został usunięty.": "",
        "Serwer został zaktualizowany.": "",
        "Serwery plików": "",
        "Sesja": "",
        "Sesja nr %0": "",
        "Sesja użytkownika": "",
        "Sierpień": "",
        "Skala 1-5": "",
        "słowa": "",
        "Słownik": "",
        "Słownik dowolny": "",
        "Słownik systemowy": "",
        "Sms": "",
        "Sortowanie malejąco": "",
        "Sortowanie rosnąco": "",
        "Sprawdzenie czy pusty": "",
        "Stała wartość": "",
        "Stan powiadomienia": "",
        "Status": "",
        "Status dokumentu został zmieniony.": "",
        "Status edytowanej wersji dokumentu": "",
        "Status formularza został zmieniony.": "",
        "Stopka": "",
        "Strona %0": "",
        "Strona główna": "",
        "Strona została usunięta.": "",
        "Stwierdzenie": "",
        "Styczeń": "",
        "Symbol klienta": "",
        "Szablon został dodany.": "",
        "Szablon został usunięty.": "",
        "Szablon został zaktualizowany.": "",
        "Szczegóły": "",
        "Szczegóły zadania": "",
        "Szczegóły zdarzenia": "",
        "Szczegóły zdarzenia nr %0": "",
        "Szerokość": "",
        "Szkic": "",
        "Szukaj": "",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "",
        "Średnio": "",
        "Tak": "",
        "Tak/Nie": "",
        "Tekst": "",
        "Tekst alternatywny": "",
        "Tekst zastępczy": "",
        "Telefon": "",
        "Ten element nie zawiera menu kontekstowego.": "",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
        "Ten zespół nie posiada jeszcze żadnych członków.": "",
        "Teraz": "",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
        "Testowe powiadomienie": "",
        "Testowy adres email": "",
        "Testowy numer telefonu": "",
        "To pole jest wymagane": "",
        "Token": "",
        "Token został usunięty.": "",
        "Tokeny": "",
        "Treści odpowiedzi są wymagane": "",
        "Treści pytań są wymagane": "",
        "Treść": "",
        "Tryb konserwacji systemu": "",
        "Trzy kolumny": "",
        "Twoje hasło wygasło. Zmień je na nowe.": "",
        "Twój token": "",
        "Tydzień": "",
        "Tylko do odczytu": "",
        "tylko dokumenty wersjonowane": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
        "Typ": "",
        "Typ konta": "",
        "Typ mime": "",
        "Typ obiektu": "",
        "Typ pola": "",
        "Typ powiadomienia": "",
        "Typ serwera": "",
        "Typ słownika": "",
        "Typ szablonu": "",
        "Typ źródła": "",
        "Typ źródła danych": "",
        "Tytuł": "",
        "Tytuł formularza jest wymagany": "",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
        "Tytuł sekcji": "",
        "Układ": "",
        "Ulica": "",
        "Upload": "",
        "Upload plików": "",
        "Uprawnienia": "",
        "Uruchom zadanie": "",
        "Urządzenie zostało usunięte.": "",
        "Usługi": "",
        "Ustaw szerokości proporcjonalnie": "",
        "Ustawienia": "",
        "Ustawienia konta": "",
        "Ustawienia powiadomień zostały zaktualizowane.": "",
        "Ustawienia zaawansowane": "",
        "Ustawienia zostały zapisane.": "",
        "Usunął": "",
        "Usunięcie": "",
        "Usunięcie wybranych elementów": "",
        "Usuń": "",
        "Usuń datę": "",
        "Usuń filtr": "",
        "Usuń plik": "",
        "Usuń plik z listy": "",
        "Usuń spacje": "",
        "Usuń wybrane": "",
        "Usuń z zespołu": "",
        "Utworzył": "",
        "Utwórz katalog": "",
        "Uwzględnij wydziały podrzędne": "",
        "Użytkownicy": "",
        "Użytkownicy w tej roli": "",
        "Użytkownik": "",
        "Użytkownik jest liderem zespołu": "",
        "Użytkownik posiada pełne uprawnienia": "",
        "Użytkownik został dodany do zespołu.": "",
        "Użytkownik został dodany.": "",
        "Użytkownik został usunięty.": "",
        "Użytkownik został zaktualizowany.": "",
        "W każdy dzień tygodnia": "",
        "W organizacji nie ma jeszcze żadnych działów.": "",
        "W przyszłości": "",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Walidacja": "",
        "Walidacja certyfikatu SSL": "",
        "Wartości": "",
        "Wartość": "",
        "Wartość domyślna": "",
        "Wartość musi być liczbą całkowitą": "",
        "Wartość musi być liczbą dodatnią": "",
        "Wartość musi być liczbą ujemną": "",
        "Warunki logiczne": "",
        "Warunkowo": "",
        "Ważne": "",
        "Ważne informacje": "",
        "Ważność": "",
        "Wczytaj zapisany filtr": "",
        "Weekend": "",
        "Wersja utowrzona przez": "",
        "Wersje": "",
        "Wersje dokumentu": "",
        "Wewnętrznie": "",
        "Wiadomość testowa została wysłana.": "",
        "Widoczność": "",
        "Wielkość kroku": "",
        "Wielokrotny wybór": "",
        "więcej...": "",
        "Więcej...": "",
        "Wklej komponent": "",
        "Włącz system powiadomień": "",
        "Włączone": "",
        "Województwo": "",
        "Wpis domyślny": "",
        "Wpis został dodany.": "",
        "Wpis został usunięty.": "",
        "Wpis został zaktualizowany.": "",
        "Wprowadzona wartość jest nieprawidłowa": "",
        "Wprowadzona wartość jest za długa": "",
        "Wprowadzona wartość jest za krótka": "",
        "Wprowadzona wartość jest za niska": "",
        "Wprowadzona wartość jest za wysoka": "",
        "Wprowadzona wartość nie jest poprawna": "",
        "Wróć do logowania": "",
        "Wróć do strony głównej": "",
        "Wrzesień": "",
        "Wstaw cztery kolumny 1/4": "",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "",
        "Wstaw dwie równe kolumny": "",
        "Wstaw kontrolkę": "",
        "Wstaw stronę": "",
        "Wstaw szablon informacyjny ze zdjęciem": "",
        "Wstaw trzy kolumny 1/3": "",
        "Wstaw z dołu": "",
        "Wstaw z góry": "",
        "Wstaw z lewej strony": "",
        "Wstaw z prawej strony": "",
        "Wstecz": "",
        "Wszyscy": "",
        "Wszystkie": "",
        "Wszystkie niezapisane zmiany zostaną utracone.": "",
        "Wszystkie próby": "",
        "wybierz": "",
        "wybierz długość": "",
        "Wybierz plik": "",
        "Wybierz plik lub katalog": "",
        "Wybierz pliki": "",
        "Wybierz pliki (do {0})": "",
        "Wybierz serwer": "",
        "Wybierz shortcode": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
        "Wybierz użytkownika": "",
        "Wybierz zaznaczone": "",
        "wybierz...": "",
        "Wybór": "",
        "Wybór języka": "",
        "Wybrana data jest mniejsza niż dozwolona %0.": "",
        "Wybrana data jest większa niż dozwolona %0.": "",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
        "Wybrana liczba jest większa niż dozwolona %0.": "",
        "Wybrane": "",
        "Wybrane elementy zostały przeniesione.": "",
        "Wybrane elementy zostały usunięte.": "",
        "Wybrane pliki": "",
        "Wybrane pliki: {0}": "",
        "Wybrany język": "",
        "Wyczyść sesję": "",
        "Wydział": "",
        "Wykonaj": "",
        "Wyloguj": "",
        "Wyloguj się": "",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wymagaj znaków specjalnych w hasłach": "",
        "Wymagane": "",
        "Wymagane jest ustawienie minimalnej długości hasła": "",
        "Wymagane jest wybranie źródła danych": "",
        "Wymiary": "",
        "Wymuś zmianę hasła": "",
        "Wyrażenia warunkowe": "",
        "Wyrażenie regularne": "",
        "Wysłane": "",
        "Wysokość": "",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
        "Wysyłaj tym kanałem": "",
        "Wyszukaj": "",
        "Wyszukaj (Ctrl+Q)": "",
        "Wyszukaj wszędzie": "",
        "Wyszukaj...": "",
        "Wyszukiwany ciąg znaków": "",
        "Wyślij": "",
        "Wyślij link": "",
        "Wyświetl również zarchiwizowane formularze": "",
        "Wyświetlam": "",
        "Wytnij": "",
        "Wyzeruj licznik": "",
        "Wzorzec": "",
        "Zaakceptowany": "",
        "Zabierz dostęp": "",
        "Zablokowany": "",
        "zacznij pisać aby wyszukać użytkownika...": "",
        "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "",
        "Zaczyna sie od": "",
        "Zadanie zostało uruchomione.": "",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zadowolony": "",
        "Zakolejkowane": "",
        "Zakończone": "",
        "Zakres czasu": "",
        "Zaloguj się": "",
        "Zaloguj się na swoje konto": "",
        "Załączniki": "",
        "Zamiany zostały zapisane.": "",
        "Zamknij": "",
        "Zaokrąglenie": "",
        "Zapamiętaj logowanie": "",
        "Zapisz": "",
        "Zapisz filtr": "",
        "Zapisz filtry": "",
        "Zapisz i wróć": "",
        "Zapisz jako": "",
        "Zapisz zmiany": "",
        "Zaplanowane": "",
        "Zapomniałeś hasła?": "",
        "Zapytanie SQL": "",
        "Zarchiwizowany": "",
        "Zasięg": "",
        "Zastępstwa": "",
        "Zastępstwo": "",
        "Zastępstwo wygasło.": "",
        "Zastępstwo wygaśnie za 10 minut.": "",
        "Zastępstwo wygaśnie za 5 minut.": "",
        "Zastępstwo wygaśnie za minutę.": "",
        "Zastępstwo zostało dodane.": "",
        "Zastępstwo zostało usunięte.": "",
        "Zastępstwo zostało zaktualizowane.": "",
        "Zastępuje osobę": "",
        "Zawiera": "",
        "Zawsze": "",
        "Zaznacz wszystkie": "",
        "Zdarzenie": "",
        "Zdecydowanie nie": "",
        "Zdecydowanie nieważne": "",
        "Zdecydowanie się nie zgadzam": "",
        "Zdecydowanie się zgadzam": "",
        "Zdecydowanie tak": "",
        "Zdecydowanie ważne": "",
        "Zdjęcie użytkownika": "",
        "Zespoły": "",
        "Zespół": "",
        "Zespół aktywny": "",
        "Zespół został dodany.": "",
        "Zespół został usunięty.": "",
        "Zespół został zaktualizowany.": "",
        "Zgoda": "",
        "Zmiana hasła": "",
        "Zmiana motywu": "",
        "Zmiana rozmiaru obrazu": "",
        "Zmień hasło": "",
        "Zmień nazwę": "",
        "Zmień nazwę katalogu": "",
        "Zmień nazwę pliku": "",
        "Zmień rozmiar": "",
        "Zmień status edytowanej wersji": "",
        "Zmień ustawienia": "",
        "Zmniejsz": "",
        "Zmodyfikował": "",
        "znaki": "",
        "Zobacz sesję użytkownika": "",
        "Zobacz szczegóły sesji użytkownika": "",
        "Zobacz szczegóły zdarzenia": "",
        "Zobacz wszystkie": "",
        "Został przywrócony oryginalny harmonogram": "",
        "Zresetuj hasło": "",
        "Zwrócony": "",
        "Źle": "",
        "Źródła danych": "",
        "Źródło": "",
        "Źródło danych": "",
        "źródło danych zostało dodane.": "",
        "Źródło danych zostało zaktualizowane.": "",
        "źródło zostało usunięte.": "",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": ""
    }
};
