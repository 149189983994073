import { Blueprint } from "../../base/blueprints/Blueprint";
import { ValidationErrors } from "../../base/types/ValidationErrors";
import { ProcessCallback } from "../types/ProcessCallback";
import { Entry, instanceOfEntry } from "./Entry";
import { FormBuilderContract } from "..";

export abstract class ValidEntry implements Entry
{
    public abstract type: string;
    public errors: ValidationErrors;

    public abstract collect(blueprint: Blueprint, builder: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>;
    public abstract validate(blueprint: Blueprint, builder: FormBuilderContract): boolean;

    public constructor()
    {
        this.errors = {};
    }

    public valid(): boolean
    {
        return Object.keys(this.errors).length == 0;
    }

    public errorMessage(name: string): string
    {
        return name in this.errors && this.errors[name].length > 0 ? this.errors[name][0] : null;
    }
}

export const instanceOfValidEntry = (object: any): object is ValidEntry =>
{
    return instanceOfEntry(object) && 'validate' in object && typeof object.validate === 'function';
};
