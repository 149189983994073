<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, AttachmentsType, AttachmentsEntry, instanceOfAttachmentsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: AttachmentsType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new AttachmentsEntry(), instanceOfAttachmentsEntry) as AttachmentsEntry);
const display = computed(() => entry.value.hasValues ? $t('[[[Posiada załączniki]]]') : null);

const reset = (): void =>
{
    entry.value.hasValues = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="pt-2">
            <ideo-form-checkbox v-model="entry.hasValues">{{ $t('[[[Posiada załączniki]]]') }}</ideo-form-checkbox>
        </div>
    </ideo-filter>
</template>
