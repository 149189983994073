import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasTitle } from '@/components/builder/form/traits/HasTitle';

export const Definition: BlueprintDefinition = {
    type: 'section',
    name: '[[[Sekcja]]]',
    icon: 'fa-square',
    group: 'primary',
    position: 8
};

export enum SectionColors {
    None = 'none',
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Danger = 'danger',
    Light = 'light',
    Dark = 'dark',
}

export interface SectionContract extends AggregateBlueprint, VisibleBlueprint, ReadonlyBlueprint, CustomErrorBlueprint, HasTitle, HasDescription, HasWidth, HasHelp
{
    color: SectionColors;
}

export class SectionType implements SectionContract, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public title: string;
    public showTitle: boolean;
    public description: string;
    public color: SectionColors;
    public components: Blueprint[];
    public help: string;
    public minWidth: number;
    public width: number;
    public exceptWidth: number[];
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.title = '';
        this.showTitle = false;
        this.description = '';
        this.color = SectionColors.None;
        this.components = [];
        this.help = '';
        this.minWidth = 3;
        this.width = Math.max(this.minWidth, width);
        this.exceptWidth = [5];
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateBlueprints(this.components);
    }
}
