<template>
    <ideo-tree-nodes :pages="pages" :expanded="expanded" :parent="null" :menu="menu" :active="active" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" class="tree">
        <template #toggle="{node}">
            <slot name="toggle" :node="node"></slot>
        </template>
        <template #icon="{node}">
            <slot name="icon" :node="node"></slot>
        </template>
        <template #default="{node}">
            <slot name="default" :node="node"></slot>
        </template>
    </ideo-tree-nodes>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { TreeNode } from '../utils';

@Options({
    name: 'ideo-tree'
})
export default class IdeoTree extends Vue
{
    @Prop()
    public pages!: TreeNode[];

    @Prop()
    public expanded!: number[];

    @Prop()
    public menu: (node: TreeNode) => Promise<any[]>;

    @Prop()
    public active: (node: TreeNode) => boolean;

    @Prop({ default: () => () => false })
    public move: (e: any) => boolean;

    @Emit('toggle')
    public onToggle(node: TreeNode): TreeNode
    {
        return node;
    }

    @Emit('click')
    public onClick(node: TreeNode): TreeNode
    {
        return node;
    }

    @Emit('sort')
    public onSort(pages: TreeNode[]): TreeNode[]
    {
        return pages;
    }
}
</script>

<style lang="scss">
.tree {
    padding-left: 0;
    list-style: none;

    ul {
        padding-left: 1.5em;
        list-style: none;
    }

    li {
        position: relative;
        padding-top: 0.5em;

        > span {
            a {
                color: var(--bs-link-color);
                padding: 0 0.5em;
                font-weight: 500;
            }

            i {
                font-size: 16px;
                color: var(--bs-body-color);
            }

            .fa-plus-square,
            .fa-minus-square,
            .fa-cog,
            .fa-trash {
                cursor: pointer;
            }
        }

        .popover-body {
            min-width: 150px;
            padding: 0 !important;
        }

        .dropdown-menu {
            position: unset;
            display: block;
            border: none;
        }
    }
}
</style>
