<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';
import { DateTime } from 'luxon';

defineOptions({
    name: 'field-datetime'
});

const props = defineProps({
  "modelValue": null,
  "label": null,
  "type": { default: 'date' }
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();
const uid = computed(() => `form-field-${instance.uid}`);

const update = (value: DateTime|null): void =>
{
    emit('update:modelValue', value);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <ideo-datetime :type="type" :id="uid" :modelValue="modelValue" @update:modelValue="update" />
    </div>
</template>
