<script lang="ts" setup>
import { ref, Ref, computed, watch, getCurrentInstance } from 'vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { Definition as RelatedParentDefinition } from '../related-parent';
import { instanceOfRelatedSelfEntry, RelatedSelfType, RelatedSelfEntry, Definition } from '.';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { useLocalization } from '@/plugins/localization';
import EntriesService from '@/modules/studio/entries/services/EntriesService';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';

const instance = getCurrentInstance();
const { $t } = useLocalization();

defineOptions({
    name: 'related-self-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new RelatedSelfEntry()) as Ref<RelatedSelfEntry>;
const limit = ref(10);

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRelatedSelfEntry);

const uid = computed(() => `form-control-${instance.uid}`);

const model = computed({
    get(): Option<number>
    {
        return {
            value: entryData.value.value,
            text: entryData.value.text
        };
    },
    set(value: Option<number>)
    {
        entryData.value.value = value?.value ?? null;
        entryData.value.text = value?.text ?? null;
    }
});

const placeholder = computed(() => props.blueprint.placeholder || $t('[[[wybierz...]]]'));
const required = computed(() => props.form.expressions.required(props.blueprint));

const components = computed<Option[]>(() =>
{
    return props.form.schema
        .components([Definition.type, RelatedParentDefinition.type], props.blueprint)
        .map(p => ({ value: p.name, text: (p as HasLabel).label }));
});

const scope = computed<number[]>(() =>
{
    return components.value
        .where(p => props.blueprint.dependsOn.includes(p.value))
        .select(p => props.entry[p.value].values as Option<number>[])
        .where(p => p != null)
        .selectMany(p => p.select((q: any) => q.value).toArray())
        .toArray();
});

watch(scope, (value: number[], old:number[]): void =>
{
    if (value.join('|') != old.join('|'))
    {
        model.value = null;
    }
});

const disabled = computed(() =>
{
    if (props.blueprint.dependsOn.length > 0)
    {
        return props.blueprint.dependsOn.length != scope.value.length;
    }

    return false;
});

const fetch = async (value: string): Promise<Option<number>> =>
{
    return new Promise(resolve => resolve(model.value));
};

const search = async (query: string): Promise<Option<number>[]> =>
{
    const pager = new Pager(1, limit.value, 'Title', 'ASC');
    const response = await EntriesService.getOptionsPublished(pager, props.entry.getFormId(), scope.value, null, query);

    return response.items.map(p => ({
        value: p.value,
        text: p.text
    }));
};

const onChanged = (value: Option<number>): void =>
{
    model.value = value;
};
</script>

<template>
    <form-component-wrapper class="related-parent-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :required="required" />
                <ideo-autocomplete :name="uid" :fetch="fetch" :search="search" :modelValue="model.value" :placeholder="placeholder" :disabled="disabled" @changed="onChanged" />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-secondary p-2 pb-0" v-if="components.length > 0">
                <field-checkboxlist v-model="blueprint.dependsOn" :options="components" :label="$t('[[[Zależna od kontrolki]]]')" class="mb-0" />
            </div>
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" :placeholder="$t('[[[wybierz...]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
