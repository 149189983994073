import { Plugin } from 'vue';
import { ReCaptchaInstance } from 'recaptcha-v3';
import { VueReCaptcha } from "vue-recaptcha-v3";
import RecaptchaService from '@/modules/core/common/services/RecaptchaService';

const RecaptchaPlugin: Plugin =
{
    async install(app, options)
    {
        const result = await RecaptchaService.fetch();

        app.config.globalProperties.recaptchaIsActive = result?.isActive;
        app.config.globalProperties.recaptchaIsActiveAndCorrect = result?.isActive && result?.isTestedAndCorrect;

        if (app.config.globalProperties.recaptchaIsActive)
        {
            app.use(VueReCaptcha, { siteKey: result.publicKey});
        }
    }
};

export default RecaptchaPlugin;

declare module '@vue/runtime-core'
{
    interface ComponentCustomProperties
    {
        $recaptcha: (action: string) => Promise<string>
        $recaptchaLoaded: () => Promise<boolean>
        $recaptchaInstance: ReCaptchaInstance
    }
}
