<script lang="ts" setup>
import { computed } from 'vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { RelatedChildrenType } from '.';
import TableView from './TableView.vue';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'related-children-blueprint',
    components: {
        ...properties,
        'table-view': TableView
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const parentId = computed(() => props.entry.getFormId ? props.entry.getFormId() : 0);
const entryId = computed(() => props.entry.getEntryId ? props.entry.getEntryId() : 0);
</script>

<template>
    <form-component-wrapper class="related-parent-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" />
                <table-view :category-id="blueprint.categoryId" :form-id="blueprint.formId" :parent-id="parentId" :entry-id="entryId"></table-view>
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-success p-2 pb-0">
                <h6>{{ $t('[[[Żródło danych]]]') }}</h6>
                <hr class="my-1">
                <field-category v-model="blueprint.categoryId" :label="$t('[[[Kategoria]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'categoryId')" />
                <field-form v-model="blueprint.formId" :category="blueprint.categoryId" :label="$t('[[[Formularz]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'formId')" :disabled="!blueprint.categoryId" />
            </div>
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <!-- <field-readonly :form="form" :blueprint="blueprint" /> -->
        </template>
    </form-component-wrapper>
</template>
