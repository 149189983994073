import { axios } from '@/plugins/axios';
import { Statement } from '@/helpers/Interfaces';

/**
 * SettingsService
 */
export default class SettingsService
{
    /**
     * @param key string
     *
     * @returns Promise<Record<string, any>>
     */
    public static async fetch(key: string): Promise<Record<string, any>>
    {
        return (await axios.get<Record<string, any>>(`admin/settings/${key}`)).data;
    }

    /**
     * @param model Record<string, any>
     *
     * @returns Promise<Statement>
     */
    public static async save(key: string, model: Record<string, any>): Promise<Statement>
    {
        return (await axios.post<Statement>(`admin/settings/${key}`, model)).data;
    }
}
