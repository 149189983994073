<template>
    <div class="form-check ms-2">
        <input :id="uid" :name="name" type="radio" class="form-check-input" ref="input" :disabled="disabled" :checked="isChecked" @change="updateModel">
        <label :for="uid" class="form-check-label" :class="{'me-3': 'default' in $slots}">
            <slot name="default"></slot>
        </label>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref, Watch } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-radio'
})
export default class IdeoFormRadio extends Vue
{
    public currentValue: any = null;

    @Ref()
    public input: () => HTMLInputElement;

    @Prop()
    public name: string;

    @Prop()
    public checked: any;

    @Prop()
    public modelValue: any;

    @Prop({ required: true })
    public value: any;

    @Prop({ default: false })
    public disabled: boolean;

    public get uid(): string
    {
        return `__IDEO__${this.$.uid}__`;
    }

    public get isChecked(): boolean
    {
        return this.currentValue === this.value;
    }

    @Emit('change', { noEmitUndefined: true })
    @Emit('update:modelValue', { noEmitUndefined: true })
    public updateModel(e: Event): any
    {
        const target = e.target as HTMLInputElement;

        if (this.name && target.checked == true)
        {
            document.getElementsByName(this.name).forEach(el =>
            {
                const element = el as HTMLInputElement;

                if (element !== target)
                {
                    element.checked = false;
                    element.dispatchEvent(new Event('change'));
                }
            });
        }

        if (target.checked)
            return this.value;
        else if (this.value === true || this.value === false)
            return !this.value;
        else
            return undefined;
    }

    @Watch('checked', { immediate: true })
    public onCheckedChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }

    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>
