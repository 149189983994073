import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'address',
    name: '[[[Adres]]]',
    icon: 'fa-map-marker-alt',
    group: 'special',
    position: 4,
    disabled: false
};

export class AddressEntry extends ValidEntry
{
    public type: string = Definition.type;
    public country?: string = null;
    public province?: string = null;
    public city?: string = null;
    public zip?: string = null;
    public street?: string = null;
    public building?: string = null;
    public apartment?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.country = data.country;
            this.province = data.province;
            this.city = data.city;
            this.zip = data.zip;
            this.street = data.street;
            this.building = data.building;
            this.apartment = data.apartment;
        }
    }

    public async collect(blueprint: AddressContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            country: this.country,
            province: this.province,
            city: this.city,
            zip: this.zip,
            street: this.street,
            building: this.building,
            apartment: this.apartment
        });
    }

    public validate(blueprint: AddressContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint))
            {
                if (blueprint.hasCountry && (this.country == null || this.country.length == 0))
                    this.errors.country = [`[[[Pole "%0" jest wymagane.|||${blueprint.getCountryLabel()}]]]`];

                if (blueprint.hasProvince && (this.province == null || this.province.length == 0))
                    this.errors.province = [`[[[Pole "%0" jest wymagane.|||${blueprint.getProvinceLabel()}]]]`];

                if (blueprint.hasCity && (this.city == null || this.city.length == 0))
                    this.errors.city = [`[[[Pole "%0" jest wymagane.|||${blueprint.getCityLabel()}]]]`];

                if (blueprint.hasZip && (this.zip == null || this.zip.length == 0))
                    this.errors.zip = [`[[[Pole "%0" jest wymagane.|||${blueprint.getZipLabel()}]]]`];

                if (blueprint.hasStreet && (this.street == null || this.street.length == 0))
                    this.errors.street = [`[[[Pole "%0" jest wymagane.|||${blueprint.getStreetLabel()}]]]`];

                if (blueprint.hasBuilding && (this.building == null || this.building.length == 0))
                    this.errors.building = [`[[[Pole "%0" jest wymagane.|||${blueprint.getBuildingLabel()}]]]`];
            }

            if (Object.keys(this.errors).length == 0 && form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfAddressEntry = (object: any): object is AddressEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface AddressContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter
{
    hasCountry: boolean;
    hasProvince: boolean;
    hasCity: boolean;
    hasZip: boolean;
    hasStreet: boolean;
    hasBuilding: boolean;
    hasApartment: boolean;
    countryLabel?: string;
    provinceLabel?: string;
    cityLabel?: string;
    zipLabel?: string;
    streetLabel?: string;
    buildingLabel?: string;
    apartmentLabel?: string;
    getCountryLabel(): string;
    getProvinceLabel(): string;
    getCityLabel(): string;
    getZipLabel(): string;
    getStreetLabel(): string;
    getBuildingLabel(): string;
    getApartmentLabel(): string;
}

export class AddressType implements AddressContract, ValidatableBlueprint, EntryFactory<AddressEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public hasCountry: boolean;
    public hasProvince: boolean;
    public hasCity: boolean;
    public hasZip: boolean;
    public hasStreet: boolean;
    public hasBuilding: boolean;
    public hasApartment: boolean;
    public countryLabel?: string;
    public provinceLabel?: string;
    public cityLabel?: string;
    public zipLabel?: string;
    public streetLabel?: string;
    public buildingLabel?: string;
    public apartmentLabel?: string;
    public help: string;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = '[[[Adres]]]';
        this.showLabel = true;
        this.hasCountry = true;
        this.hasProvince = true;
        this.hasCity = true;
        this.hasZip = true;
        this.hasStreet = true;
        this.hasBuilding = true;
        this.hasApartment = true;
        this.help = '';
        this.minWidth = 3;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public getCountryLabel(): string { return this.countryLabel || '[[[Kraj]]]'; }
    public getProvinceLabel(): string { return this.provinceLabel || '[[[Województwo]]]'; }
    public getCityLabel(): string { return this.cityLabel || '[[[Miejscowość]]]'; }
    public getZipLabel(): string { return this.zipLabel || '[[[Kod pocztowy]]]'; }
    public getStreetLabel(): string { return this.streetLabel || '[[[Ulica]]]'; }
    public getBuildingLabel(): string { return this.buildingLabel || '[[[Numer domu]]]'; }
    public getApartmentLabel(): string { return this.apartmentLabel || '[[[Numer mieszkania]]]'; }

    public createEntry(data: any): AddressEntry
    {
        return new AddressEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
