// Blueprints
import { Blueprint } from "../../base/blueprints/Blueprint";

// Entries
import { Entry } from "../entries/Entry";
import { RootEntry, RootEntryAbstract, instanceOfRootEntry } from "../entries/RootEntry";
import { EntryFactory, instanceOfEntryFactory } from "../traits/EntryFactory";
import { ValidEntry, instanceOfValidEntry } from "../entries/ValidEntry";

// Managers
import { SchemaManager } from "../../base/managers/SchemaManager";

// --------------------------------------------------

export class DocumentManager
{
    private root: RootEntry;
    private schema: SchemaManager;
    private identity: () => number;

    public constructor(schemaManager: SchemaManager)
    {
        this.schema = schemaManager;
        this.setRootEntry(null);
    }

    public get id(): number
    {
        return this.identity ? this.identity() : 0;
    }

    public setIdentity(callback: () => number): void
    {
        this.identity = callback;
    }

    public getRootEntry(): Entry
    {
        return this.root;
    }

    public setRootEntry(entry: Entry|null): void
    {
        const blueprint = this.schema.getBlueprint();

        if (instanceOfEntryFactory(blueprint))
        {
            if (entry == null)
                entry = blueprint.createEntry({});

            if (!instanceOfRootEntry(entry))
                throw new Error("Argument 'entry' must be of type RootEntry.");

            if (!(entry instanceof RootEntryAbstract))
                entry = this.prepareRootEntry(entry);

            this.root = entry as RootEntry;
        }
    }

    private prepareRootEntry(entry: Entry): RootEntry
    {
        const blueprint = this.schema.getBlueprint() as EntryFactory<any>;
        const root = blueprint.createEntry({});

        this.schema.components(null, blueprint).forEach(blueprint =>
        {
            if (instanceOfEntryFactory(blueprint))
            {
                root[blueprint.name] = blueprint.createEntry(blueprint.name in entry ? entry[blueprint.name] : null);
            }
        });

        return root;
    }

    public initEntry(blueprint: Blueprint & EntryFactory<any>, entry: any, typeCheck: (entry: any) => boolean): any
    {
        if (!(blueprint.name in this.root && typeCheck(this.root[blueprint.name])))
        {
            this.root[blueprint.name] = entry;
        }

        return this.root[blueprint.name];
    }

    public getEntry(blueprint: Blueprint & EntryFactory<any>): Entry
    {
        if (blueprint.name in this.root && this.root[blueprint.name].type != blueprint.type)
        {
            this.removeEntry(blueprint.name);
        }

        if (!(blueprint.name in this.root))
        {
            this.root[blueprint.name] = blueprint.createEntry(null);
        }

        return this.root[blueprint.name];
    }

    public renameEntry(name: string, old: string): void
    {
        if (old && old.length > 0 && old in this.root)
        {
            this.root[name] = this.root[old];

            delete this.root[old];
        }
    }

    public removeEntry(name: string): void
    {
        if (name in this.root)
        {
            delete this.root[name];
        }
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        Object.keys(this.root).forEach(property =>
        {
            if (instanceOfValidEntry(this.root[property]))
            {
                const name = `${property.toLowerCase()}.`;
                const entry = this.root[property] as ValidEntry;

                Object.entries(errors).filter(([key]) => key.startsWith(name)).forEach(([key, messages]) =>
                {
                    entry.errors[key.substring(name.length)] = messages;
                });
            }
        });
    }
}
