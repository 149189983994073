<script lang="ts" setup>
import { computed } from 'vue';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import CategoriesService from '@/modules/studio/categories/services/CategoriesService';
import FieldAutocomplete from './FieldAutocomplete.vue';

defineOptions({
    name: 'field-category',
    components: {
        'field-autocomplete': FieldAutocomplete
    }
});

const props = defineProps({
  "label": null,
  "modelValue": null,
  "invalidFeedback": { type: Function,  },
  "disabled": { type: Boolean, default: false }
});

const emit = defineEmits(["update:modelValue"]);

const triggerUpdate = (value: number): void =>
{
    emit('update:modelValue', value);
};

const model = computed({
    get(): number
    {
        return props.modelValue;
    },
    set(value: number)
    {
        triggerUpdate(Number(value));
    }
});

const fetchCategory = async (id: number): Promise<Option> =>
{
    const response = await CategoriesService.fetchCategory(id);

    if (response)
    {
        return {
            value: response.result.id.toString(),
            text: response.result.name
        };
    }

    return null;
};

const searchCategories = async (query: string, limit: number): Promise<Option[]> =>
{
    const pager = new Pager(1, limit, 'Name', 'ASC');
    const response = await CategoriesService.getCategories({ name: query }, pager);

    return response.items.map(p =>
    {
        return {
            value: p.id.toString(),
            text: p.name
        };
    });
};
</script>

<template>
    <field-autocomplete v-model="model" :label="label" :fetch="fetchCategory" :search="searchCategories" :invalid-feedback="invalidFeedback" :disabled="disabled" />
</template>
