import { Blueprint } from "./Blueprint";
import { AggregateBlueprint, instanceOfAggregateBlueprint } from "./AggregateBlueprint";

export interface DocumentBlueprint extends AggregateBlueprint
{
    kind: 'document';
}

export interface DocumentConstructor
{
    new (id: string, name: string): DocumentBlueprint;
}

export const instanceOfDocumentBlueprint = (object: any): object is DocumentBlueprint =>
{
    return instanceOfAggregateBlueprint(object) && 'kind' in object && object.kind === 'document';
};

export abstract class DocumentAbstract implements DocumentBlueprint
{
    public kind: 'document' = 'document' as const;
    public id: string;
    public type: string;
    public name: string;
    public disabled?: boolean = false;
    public components: Blueprint[] = [];

    public constructor(id: string, name: string, type: string)
    {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}
