// Base
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DocumentAbstract, DocumentBlueprint } from '@/components/builder/base/blueprints/DocumentBlueprint';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { ValidatableBlueprint, setValidationErrors, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';

// Blueprints
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';

// Traits
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasTitle } from '@/components/builder/form/traits/HasTitle';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';

// Entries
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry, instanceOfValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { RootEntryAbstract } from '@/components/builder/form/entries/RootEntry';

// Types
import { ProcessCallback, defaultCallback } from '@/components/builder/form/types/ProcessCallback';

// Form
import { FormBuilderContract } from '@/components/builder/form';

// --------------------------------------------------

export const Definition: BlueprintDefinition = {
    type: 'form',
    name: '[[[Formularz]]]',
    group: 'none'
};

export enum ProgressChoice {
    Steps = 'Steps',
    Bar = 'Bar',
    Tabs = 'Tabs',
    None = 'None'
}

export enum TabsType {
    Tabs = 'Tabs',
    Pills = 'Pills',
    Underline = 'Underline'
}

export interface FormContract extends DocumentBlueprint, AggregateBlueprint, HasTitle, HasDescription
{
    sendButtonText?: string;
    showSendButton: boolean;
    showPageNumbers: boolean;
    showPageTitles: boolean;
    progressType: ProgressChoice;
    tabsType: TabsType;
    tabsJustified: boolean;
    currentPage: number;
    category: number;
}

export class FormType extends DocumentAbstract implements EntryFactory<FormEntry>, FormContract, ValidatableBlueprint
{
    public title: string;
    public showTitle: boolean;
    public description: string;
    public sendButtonText?: string;
    public showSendButton: boolean;
    public showPageNumbers: boolean;
    public showPageTitles: boolean;
    public progressType: ProgressChoice;
    public tabsType: TabsType;
    public tabsJustified: boolean;
    public currentPage: number;
    public category: number;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        super(id, name, 'form');

        this.title = '[[[Formularz]]]';
        this.showTitle = false;
        this.description = '';
        this.showSendButton = false;
        this.showPageNumbers = false;
        this.showPageTitles = true;
        this.progressType = ProgressChoice.Steps;
        this.tabsType = TabsType.Tabs;
        this.tabsJustified = false;
        this.currentPage = 1;
        this.category = null;
        this.errors = {};
    }

    public createEntry(data: any): FormEntry
    {
        return new FormEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const TITLE_LENGTH = 250;

        if (!this.title)
            this.errors.title = ['[[[Tytuł formularza jest wymagany]]]'];
        else if (this.title.length > TITLE_LENGTH)
            this.errors.title = [`[[[Tytuł formularza nie może być dłuższy niż %0 znaków|||${TITLE_LENGTH}]]]`];

        if (!this.category)
            this.errors.category = ['[[[Kategoria jest wymagana]]]'];

        const errors = {
            [this.name]: this.errors,
            ...validateBlueprints(this.components)
        };

        return errors;
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        setValidationErrors(this, errors);
    }
}

export const instanceOfFormType = (object: any): object is FormType =>
{
    return instanceOfAggregateBlueprint(object) && 'type' in object && object.type === Definition.type;
};

export class FormEntry extends RootEntryAbstract
{
    [prop: string]: any;

    public constructor(data: any = null)
    {
        super(Definition.type);

        if (data !== null)
        {
            Object.entries(data).forEach(([name, value]) =>
            {
                this[name] = value;
            });
        }
    }

    public async collect(form: FormContract, builder: FormBuilderContract, preprocess: ProcessCallback = defaultCallback): Promise<Entry>
    {
        await preprocess(form, this, builder.blueprintId, builder.entryId);

        const result = {
            type: this.type
        };
        const properties = Object.keys(this);

        for (let i = 0; i < properties.length; i++)
        {
            const name = properties[i];

            if (instanceOfValidEntry(this[name]))
            {
                const entry = this[name] as ValidEntry;
                const blueprint = builder.schema.find(name);

                result[name] = await entry.collect(blueprint, builder, preprocess);
            }
        }

        return entry(result);
    }

    public validate(form: FormContract, builder: FormBuilderContract): boolean
    {
        this.errors = {};

        Object.keys(this).forEach(property =>
        {
            if (instanceOfValidEntry(this[property]))
            {
                const entry = this[property] as ValidEntry;
                const blueprint = builder.schema.find(property);

                if (!entry.validate(blueprint, builder))
                {
                    Object.keys(entry.errors).forEach(key =>
                    {
                        this.errors[`${property}.${key}`] = entry.errors[key];
                    });
                }
            }
        });

        return this.valid();
    }

    public find(name: string): ValidEntry
    {
        if (name in this && instanceOfValidEntry(this[name]))
        {
            return this[name];
        }

        return null;
    }
}
