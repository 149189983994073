<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, LikertType, LikertEntry, instanceOfLikertEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: LikertType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new LikertEntry(), instanceOfLikertEntry) as LikertEntry);
const questions = computed(() => component.value.questions.map((value, i) =>
{
    return { value: i, text: $t(value), selected: false };
}));
const choices = computed(() => component.value.choices.map((value, i) =>
{
    return { value: i + 1, text: $t(value), selected: false };
}));
const display = computed(() =>
{
    const count = questions.value.filter((q, i) => entry.value.values && entry.value.values[i] !== 0).length;

    if (count > 0)
        return $t('[[[Wybrane %0 na %1|||{0}|||{1}]]]', count, questions.value.length);

    return null;
});

const value = (question: any): any =>
{
    entry.value.values = entry.value.values || {};
    entry.value.values[question.value] = entry.value.values[question.value] || Number(component.value.defaultValue ?? 0);

    return entry.value.values[question.value];
};

const update = (question: any, e: any): any =>
{
    entry.value.values = entry.value.values || {};
    entry.value.values[question.value] = e;
};

const reset = (): void =>
{
    questions.value.forEach(q => entry.value.values[q.value] = null);
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="mb-n2">
            <div v-for="question in questions" :key="question.value">
                <ideo-form-group :label="question.text">
                    <ideo-form-select :options="choices" :model-value="value(question)" @update:model-value="update(question, $event)" />
                </ideo-form-group>
            </div>
        </div>
    </ideo-filter>
</template>
