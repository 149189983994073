<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';
import { Option } from '@/helpers/Interfaces';

defineOptions({
    name: 'field-choice'
});

defineProps({
  "label": null,
  "options": null,
  "modelValue": null
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();

const uid = computed(() => `form-field-${instance.uid}`);

const update = (e: any): void =>
{
    emit('update:modelValue', e?.target?.value);
};
</script>


<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <select class="form-select" :id="uid" :value="modelValue" @change="update">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ $t(option.text) }}
            </option>
        </select>
    </div>
</template>
