import { computed } from 'vue';
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ColorTranslator } from 'colortranslator';
import SettingsService from '@/modules/core/common/services/SettingsService';

type Logo = {
    default: string,
    small: string
};
type Palette = {
    primary?: string,
    secondary?: string,
    nav?: {
        bg: string,
        color: string
    },
    menu?: {
        active: {
            bg: string,
            color: string
        }
    }
};

const name = 'ideo';

export const LOGO: Record<string, Logo> = {
    'light': {
        default: `/static/img/${name}-logo-color.png`,
        small: `/static/img/${name}-logo-color-sm.png`,
    },
    'dark': {
        default: `/static/img/${name}-logo-white.png`,
        small: `/static/img/${name}-logo-white-sm.png`,
    }
};

export const PALETTES: Record<string, Palette> = {
    'default': {
        primary: 'var(--bs-orange)'
    },
    'blue': {
        primary: '#01719f',
        secondary: '#0295d4'
    },
    'graphite': {
        primary: '#2C363E',
        secondary: '#4b5a68'
    },
    'darkred': {
        primary: '#990000',
        secondary: '#DD0000',
    },
    'darkgreen': {
        primary: '#054700',
        secondary: '#217a00'
    },
    'violet': {
        primary: '#670c45',
        secondary: '#993b89'
    }
};
export const BACKGROUNDS: Record<string, Palette> = {
    'default': {
        nav: {
            bg: 'var(--ideo-accent-bg)',
            color: 'var(--bs-white)',
        }
    },
    'white': {
        nav: {
            bg: '#FFFFFF',
            color: '#3D3D3D',
        }
    },
};

export const useThemeStore = defineStore('theme', () =>
{
    const state = useStorage('theme', {
        name: 'light',
        colors: {
            primary: '',
            secondary: '',
            nav: {
                bg: '',
                color: ''
            },
            menu: {
                active: {
                    color: ''
                }
            }
        },
        locale: ''
    },
    localStorage,
    {mergeDefaults: true});

    const theme = computed(() => state.value.name);
    const colors = computed(() => state.value.colors);
    const locale = computed({
        get: () => state.value.locale,
        set: (value) =>
        {
            state.value.locale = value;
        }
    });

    function changeTheme(): void
    {
        state.value.name = state.value.name == 'light' ? 'dark' : 'light';

        setTheme(theme.value);
    }

    async function initColors(): Promise<void>
    {
        try
        {
            const result = await SettingsService.fetch('colors');

            colors.value.primary = result.primary;
            colors.value.secondary = result.secondary;
            colors.value.nav.bg = result.navBackground;
            colors.value.nav.color = result.navColor;
            colors.value.menu.active.color = result.menuColor;

            updateColors();
        }
        catch
        {
            colors.value.primary = '';
            colors.value.secondary = '';
            colors.value.nav.bg = '';
            colors.value.nav.color = '';
            colors.value.menu.active.color = '';
        }
    }

    function updateButtonColor(rule: CSSStyleRule, color: ColorTranslator): void
    {
        const luminance = 0.2126 * color.R + 0.7152 * color.G + 0.0722 * color.B;
        const blackLuminance = 0;
        const whiteLuminance = 255;
        const fontColor = (whiteLuminance - luminance) < (luminance - blackLuminance) ? '#000000' : '#FFFFFF';

        rule.style.setProperty('--bs-btn-color', fontColor);
        rule.style.setProperty('--bs-btn-hover-color', fontColor);
        rule.style.setProperty('--bs-btn-active-color', fontColor);
        rule.style.setProperty('--bs-btn-disabled-color', fontColor);

        rule.style.setProperty('--bs-btn-bg', color.HEX);
        rule.style.setProperty('--bs-btn-border-color', color.HEX);
        rule.style.setProperty('--bs-btn-disabled-bg', color.HEX);
        rule.style.setProperty('--bs-btn-disabled-border-color', color.HEX);

        color.setL(color.L - 5);

        rule.style.setProperty('--bs-btn-hover-bg', color.HEX);
        rule.style.setProperty('--bs-btn-hover-border-color', color.HEX);
        rule.style.setProperty('--bs-btn-active-bg', color.HEX);
        rule.style.setProperty('--bs-btn-active-border-color', color.HEX);
    }

    function updateColors(): void
    {
        const root = document.querySelector(':root') as Element & { style: CSSStyleDeclaration };
        const rules = [...document.styleSheets].selectMany(p => [...p.cssRules]).toArray() as CSSStyleRule[];
        const rule = (selector: string): CSSStyleRule => rules.where(p => p.selectorText == selector).first();

        if (state.value.colors.primary)
        {
            const primary = new ColorTranslator(colors.value.primary);

            root.style.setProperty('--bs-primary', primary.HEX);
            root.style.setProperty('--bs-primary-rgb', `${primary.RGBAObject.r}, ${primary.RGBAObject.g}, ${primary.RGBAObject.b}`);

            updateButtonColor(rule('.btn-primary'), primary);
        }

        if (state.value.colors.secondary)
        {
            const secondary = new ColorTranslator(colors.value.secondary);

            root.style.setProperty('--bs-secondary', secondary.HEX);
            root.style.setProperty('--bs-secondary-rgb', `${secondary.RGBAObject.r}, ${secondary.RGBAObject.g}, ${secondary.RGBAObject.b}`);

            updateButtonColor(rule('.btn-secondary'), secondary);
        }

        // Navbar
        if (colors.value.nav.bg)
            root.style.setProperty('--ideo-nav-bg', colors.value.nav.bg);

        if (colors.value.nav.color)
            root.style.setProperty('--ideo-nav-color', colors.value.nav.color);

        // Menu
        if (colors.value.menu.active.color)
            root.style.setProperty('--ideo-menu-active-color', colors.value.menu.active.color);
    }

    function setTheme(name: string): void
    {
        document.documentElement.setAttribute('data-bs-theme', name);
    }

    setTheme(theme.value);

    return { theme, locale, colors, changeTheme, initColors, updateColors };
});
