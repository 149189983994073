import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { SecureEntry } from '@/components/builder/form/entries/SecureEntry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasAffix } from '@/components/builder/form/traits/HasAffix';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { AffixValue } from '@/components/builder/form/types/AffixValue';
import { MinMaxValue } from '@/components/builder/form/types/MinMaxValue';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'text',
    name: '[[[Tekst]]]',
    icon: 'fa-font',
    group: 'primary',
    position: 1
};

export enum TextFieldTypes {
    Text = 'Text',
    Multiline = 'Multiline',
    Password = 'Password'
}

export enum ValidationTypes {
    None = 'None',
    Alphabetic = 'Alphabetic',
    Numeric = 'Numeric',
    Alphanumeric = 'Alphanumeric',
    RegularExpression = 'RegularExpression'
}

// --------------------------------------------------

export interface HasValidation extends Blueprint
{
    validation: ValidationTypes;
    validationRule: string;
    validationMessage: string;
}

export const instanceOfHasValidation = (object: any): object is HasValidation =>
{
    return object && 'validation' in object && 'validationRule' in object && 'validationMessage' in object;
};

export const validator = (blueprint: HasValidation): (value: string) => string =>
{
    const validate = (regexp: RegExp, value: string, message: string): string =>
    {
        return regexp.test(value) ? null : message;
    };
    let rule = (value: string): string => validate(new RegExp("^.*$", "g"), value, null);

    if (instanceOfHasValidation(blueprint))
    {
        switch (blueprint.validation)
        {
            case ValidationTypes.Alphabetic:
                rule = (value: string) => validate(new RegExp("^[a-z]*$", "gi"), value, '[[[Pole może zawierać jedynie litery.]]]');
                break;
            case ValidationTypes.Numeric:
                rule = (value: string) => validate(new RegExp("^[0-9]*$", "g"), value, '[[[Pole może zawierać jedynie cyfry.]]]');
                break;
            case ValidationTypes.Alphanumeric:
                rule = (value: string) => validate(new RegExp("^[a-z0-9]*$", "gi"), value, '[[[Pole może zawierać jedynie litery i cyfry.]]]');
                break;
            case ValidationTypes.RegularExpression:
                rule = (value: string) => validate(new RegExp(blueprint.validationRule, "gi"), value, blueprint.validationMessage || '[[[Podano nieprawidłowe dane.]]]');
                break;
        }
    }

    return rule;
};

// --------------------------------------------------

export class TextEntry extends ValidEntry implements SecureEntry
{
    public type: string = Definition.type;
    public value?: string = null;
    public secure: boolean = false;
    public isHeader: boolean = false;
    public isSummary: boolean = false;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
            this.secure = data.secure;
            this.isHeader = data.isHeader;
            this.isSummary = data.isSummary;
        }
    }

    public async collect(blueprint: TextContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            value: this.value ?? (form.expressions.executeExpression(blueprint.defaultValue) || ''),
            secure: blueprint.fieldType == TextFieldTypes.Password,
            isHeader: this.isHeader,
            isSummary: this.isSummary
        });
    }

    public validate(blueprint: TextContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        let message = null;
        const value = this.value ?? (form.expressions.executeExpression(blueprint.defaultValue) || '');

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            const required = form.expressions.required(blueprint);

            if (required && value.length == 0)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (value.length < blueprint.characters.min)
            {
                this.errors.value = [`[[[Nie podano wymaganej ilości znaków: %0.|||${blueprint.characters.min}]]]`];
            }
            else if (blueprint.characters.max != null && value.length > blueprint.characters.max)
            {
                this.errors.value = [`[[[Przekroczono dozwoloną ilość znaków: %0.|||${blueprint.characters.max}]]]`];
            }
            else if (!(!required && value.length == 0) && (message = validator(blueprint)(value)) != null)
            {
                this.errors.value = [message];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfTextEntry = (object: any): object is TextEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface TextContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasFilter, HasValidation
{
    fieldType: TextFieldTypes;
    defaultValue: string;
    characters: MinMaxValue;
    rows: number;
    isHeader: boolean;
    isSummary: boolean;
}

export class TextType implements TextContract, ValidatableBlueprint, EntryFactory<TextEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public fieldType: TextFieldTypes;
    public defaultValue: string;
    public characters: MinMaxValue;
    public rows: number;
    public affix: AffixValue;
    public help: string;
    public minWidth: number;
    public width: number;
    public validation: ValidationTypes;
    public validationRule: string;
    public validationMessage: string;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;
    public showFilter: boolean;
    public isHeader: boolean;
    public isSummary: boolean;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = '[[[Tekst]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.defaultValue = '';
        this.characters = { min: 0, max: 50 };
        this.rows = 2;
        this.help = '';
        this.affix = { prepend: '', append: '' };
        this.fieldType = TextFieldTypes.Text;
        this.validation = ValidationTypes.None;
        this.validationRule = '';
        this.validationMessage = '';
        this.minWidth = 1;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
        this.isHeader = false;
        this.isSummary = false;
    }

    public createEntry(data: any): TextEntry
    {
        return new TextEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const isNotPCRE = /^\/.+\/[gimuy]*$|^[^/]+\/[gimuy]*$|^\/[^/]*$/;

        if (isNotPCRE.test(this.validationRule))
        {
            this.errors.validationRule = ['[[[Wyrażenie w postaci "/pattern/flag" nie jest obsługiwane, użyj same wartości pattern]]]'];
        }
        else
        {
            try { new RegExp(this.validationRule); }
            catch (e) { this.errors.validationRule = ['[[[Niepoprawne wyrażenie regularne]]]']; }
        }

        return {
            [this.name]: this.errors
        };
    }
}
