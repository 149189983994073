<script lang="ts" setup>
import { ref, Ref, computed, getCurrentInstance, onBeforeMount } from 'vue';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { instanceOfDictionaryEntry, DictionaryType, DictionaryEntry } from '.';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { useLocalization } from '@/plugins/localization';
import DataSourcesService from '@/modules/core/data-sources/services/DataSourcesService';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

const { $t } = useLocalization();

defineOptions({
    name: 'dictionary-blueprint',
    components: {
        'multiselect': Multiselect,
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new DictionaryEntry()) as Ref<DictionaryEntry>;
const dictionaries = ref<Option[]>([]);
const limit = ref<number>(10);

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfDictionaryEntry);

onBeforeMount(async () =>
{
    if (props.form.designMode())
    {
        dictionaries.value = await DataSourcesService.getDictionaries();

        if (props.blueprint.sourceId === null && dictionaries.value.length > 1)
        {
            blueprint.value.sourceId = parseInt(dictionaries.value[0].value);
        }
    }
});

const instance = getCurrentInstance();
const uid = computed(() => `form-control-${instance.uid}`);

const model = computed({
    get()
    {
        return {
            value: entryData.value.value,
            text: entryData.value.text
        };
    },
    set(model: Option)
    {
        entryData.value.value = model.value;
        entryData.value.text = model.text;
    }
});

const placeholder = computed(() => props.blueprint.placeholder || $t('[[[wybierz...]]]'));

const required = computed(() => props.form.expressions.required(props.blueprint));

const fetch = async (): Promise<Option> =>
{
    return new Promise(resolve => resolve(model.value));
};

const search = async (query: string): Promise<Option[]> =>
{
    const pager = new Pager(1, limit.value);
    const response = await DataSourcesService.getOptions(props.blueprint.sourceId, query, pager);

    return response.items.map(p =>
    {
        return {
            value: p.value.toString(),
            text: p.text
        };
    });
};

const onChanged = (value: Option): void =>
{
    model.value = value;
};
</script>

<template>
    <form-component-wrapper class="dictionary-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :required="required" />
                <ideo-autocomplete :name="uid" :fetch="fetch" :search="search" :modelValue="model.value" :placeholder="placeholder" @changed="onChanged" />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-select v-model="blueprint.sourceId" :options="dictionaries" :label="$t('[[[Źródło danych]]]')" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" :placeholder="$t('[[[wybierz...]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
