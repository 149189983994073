<script lang="ts" setup>
import { computed } from 'vue';
import { Definition, DateType, DateEntry, instanceOfDateEntry, DateFieldTypes } from '.';
import { formatDateTime } from '@/helpers/Utils';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: DateType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new DateEntry(), instanceOfDateEntry) as DateEntry);
const display = computed(() => entry.value.value
    ? formatDateTime(entry.value.value, component.value.fieldType == DateFieldTypes.DateTime
        ? 'yyyy-MM-dd HH:mm:ss'
        : 'yyyy-MM-dd'
    )
    : null
);

const reset = (): void =>
{
    entry.value.value = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-datetime v-model="entry.value" :type="component.fieldType.toLowerCase()" :id="component.id" />
    </ideo-filter>
</template>
