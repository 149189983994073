<script lang="ts" setup>
import { computed } from 'vue';
import { Definition, PersonalType, PersonalEntry, instanceOfPersonalEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: PersonalType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new PersonalEntry(), instanceOfPersonalEntry) as PersonalEntry);
const display = computed(() => `${entry.value.title ?? ''} ${entry.value.givenName ?? ''} ${entry.value.middleName ?? ''} ${entry.value.surname ?? ''}`.trim() || null);

const reset = (): void =>
{
    entry.value.title = null;
    entry.value.givenName = null;
    entry.value.middleName = null;
    entry.value.surname = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="mb-n2">
            <div class="mb-2" v-if="component.hasTitle">
                <input type="text" class="form-control" v-model="entry.title" :placeholder="$t('[[[Tytuł]]]')">
            </div>
            <div class="mb-2">
                <input type="text" class="form-control" v-model="entry.givenName" :placeholder="$t('[[[Imię]]]')">
            </div>
            <div class="mb-2" v-if="component.hasMiddleName">
                <input type="text" class="form-control" v-model="entry.middleName" :placeholder="$t('[[[Drugie imię]]]')">
            </div>
            <div class="mb-2">
                <input type="text" class="form-control" v-model="entry.surname" :placeholder="$t('[[[Nazwisko]]]')">
            </div>
        </div>
    </ideo-filter>
</template>
