<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import { ChoiceOption } from '@/components/builder/form/types/ChoiceOption';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-options'
});

const props = defineProps({
  "label": null,
  "modelValue": null,
  "invalidFeedback": { type: Function, default: null },
  "multiple": { type: Boolean, default: false },
  "canSelect": { type: Boolean, default: true },
  "hasValue": { type: Boolean, default: true }
});
const modelValue = computed(() => props.modelValue);

const emit = defineEmits(["update:modelValue"]);

const uid = computed(() => `form-field-${instance.uid}`);
const value = computed<ChoiceOption[]>(() => props.modelValue);

const update = (value: ChoiceOption[]): void =>
{
    emit('update:modelValue', value);
};

const addOption = (option: ChoiceOption): void =>
{
    const item = {
        value: (props.modelValue.length + 1).toString(),
        text: '',
        selected: false
    };

    modelValue.value.splice(props.modelValue.indexOf(option) + 1, 0, item);
    update(modelValue.value);
};

const removeOption = (option: ChoiceOption): void =>
{
    update(modelValue.value.filter(p => p != option));
};

const onChange = (option: ChoiceOption): void =>
{
    if (props.multiple == false)
    {
        modelValue.value.filter(p => p != option).forEach(p => { p.selected = false; });
    }

    option.selected = !option.selected;

    update(modelValue.value);
};

const onBlur = (option: ChoiceOption): void =>
{
    if (option.value === null || option.value == '')
    {
        option.value = (modelValue.value.indexOf(option) + 1).toString();
    }

    update(modelValue.value);
};

const onInput = (): void =>
{
    update(modelValue.value);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <div v-for="(option, i) in value" :key="i">
                <div class="input-group mb-2">
                    <template v-if="canSelect">
                        <div class="input-group-text" v-if="multiple">
                            <span class="fa-stack" v-if="option.selected">
                                <i class="fas fa-square fa-stack-1x text-white"></i>
                                <i class="far fa-check-square fa-stack-1x text-primary" @click="onChange(option)"></i>
                            </span>
                            <span class="fa-stack" v-else>
                                <i class="fas fa-square fa-stack-1x text-white"></i>
                                <i class="far fa-square fa-stack-1x text-secondary" @click="onChange(option)"></i>
                            </span>
                        </div>
                        <div class="input-group-text" v-else>
                            <span class="fa-stack" v-if="option.selected">
                                <i class="fas fa-circle fa-stack-1x text-white"></i>
                                <i class="fas fa-dot-circle fa-stack-1x text-primary" @click="onChange(option)"></i>
                            </span>
                            <span class="fa-stack" v-else>
                                <i class="fas fa-circle fa-stack-1x text-white"></i>
                                <i class="far fa-circle fa-stack-1x text-secondary" @click="onChange(option)"></i>
                            </span>
                        </div>
                    </template>
                    <input type="text" v-model="option.text" @input="onInput" class="form-control text">
                    <input type="text" v-model="option.value" @input="onInput" class="form-control value" placeholder="#" @blur="onBlur(option)" v-if="hasValue">
                    <button class="btn btn-outline-secondary" type="button" @click.stop.prevent="addOption(option)">
                        <i class="far fa-plus text-success"></i>
                    </button>
                    <button class="btn btn-outline-secondary" type="button" :disabled="modelValue.length <= 1" @click.stop.prevent="removeOption(option)">
                        <i class="far fa-times text-danger"></i>
                    </button>
                </div>
            </div>
        </div>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>

<style lang="scss" scoped>
// .text {
//     border-color: var(--ideo-border-color) !important;
// }

.value {
    flex: none !important;
    width: 50px !important;
}

.input-group-text {
    font-size: 16px;
    padding: 0;

    .fa-stack {
        font-size: 12px;
        margin-top: 1px;

        .text-secondary {
            color: var(--bs-gray-600) !important;
        }
    }
}
</style>
