import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'composite',
    name: '[[[...]]]',
    icon: 'fa-cart-shopping-fast',
    group: 'composite',
    position: 1
};

export class CompositeEntry extends ValidEntry
{
    public type: string = Definition.type;
    public value?: any = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: CompositeContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            value: this.value || form.expressions.executeExpression(blueprint.defaultValue)
        });
    }

    public validate(blueprint: CompositeContract, form: FormBuilderContract): boolean
    {
        this.errors = {};
        this.value = this.value || form.expressions.executeExpression(blueprint.defaultValue);

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && form.expressions.executeExpression(blueprint.requiredCondition) === false)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfCompositeEntry = (object: any): object is CompositeEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface CompositeContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter
{
    [key: string]: any;

    composite: string;
    defaultValue: string;
    requiredCondition: string;
}

export class CompositeType implements CompositeContract, ValidatableBlueprint, EntryFactory<CompositeEntry>
{
    public composite: string;
    public id: string;
    public type: string;
    public name: string;
    public minWidth: number;
    public width: number;
    public errors: ValidationErrors;
    public label: string;
    public showLabel: boolean;
    public defaultValue: any;
    public help: string;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public requiredCondition: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public showFilter: boolean;

    public constructor(id: string, name: string, width: number)
    {
        this.composite = null;
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.minWidth = 1;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.errors = {};
        this.label = '...';
        this.showLabel = true;
        this.defaultValue = null;
        this.help = '';
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.requiredCondition = null;
        this.customErrorMessage = '';
        this.showFilter = false;
    }

    public createEntry(data: any): CompositeEntry
    {
        return new CompositeEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
