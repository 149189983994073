<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, BooleanType, BooleanEntry, instanceOfBooleanEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: BooleanType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new BooleanEntry(), instanceOfBooleanEntry) as BooleanEntry);
const display = computed(() => entry.value.value ? $t('[[[Tak]]]') : null);

const reset = (): void =>
{
    entry.value.value = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="pt-2">
            <ideo-form-checkbox v-model="entry.value">{{ component.label }}</ideo-form-checkbox>
        </div>
    </ideo-filter>
</template>
