import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { HasPlaceholder } from '@/components/builder/form/traits/HasPlaceholder';
import { ChoiceOption } from '@/components/builder/form/types/ChoiceOption';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'choice',
    name: '[[[Wybór]]]',
    icon: 'fa-list',
    group: 'primary',
    position: 2
};

export enum ChoiceFieldTypes {
    Dropdown = 'Dropdown',
    Radio = 'Radio',
    Checkbox = 'Checkbox'
}

export class ChoiceEntry extends ValidEntry
{
    public type: string = Definition.type;
    public values?: string[] = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.values = data.values;
        }
    }

    public get value(): string
    {
        return this.values && this.values.length > 0 ? this.values[0] : null;
    }

    public async collect(blueprint: ChoiceContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        return entry({
            type: this.type,
            values: this.getValues(blueprint, form)
        });
    }

    public validate(blueprint: ChoiceContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const values = this.getValues(blueprint, form);

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && (values == null || values.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }

    private getValues(blueprint: ChoiceContract, form: FormBuilderContract): string[]
    {
        if (this.values !== null)
            return this.values;

        if (blueprint.defaultValue)
            return [form.expressions.executeExpression(blueprint.defaultValue)];

        return blueprint.options.filter(p => p.selected).map(p => p.value);
    }
}

export const instanceOfChoiceEntry = (object: any): object is ChoiceEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface ChoiceContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth, HasFilter
{
    fieldType: ChoiceFieldTypes;
    options: ChoiceOption[];
    defaultValue: string;
    layout: number;
}

export class ChoiceType implements ChoiceContract, ValidatableBlueprint, EntryFactory<ChoiceEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public help: string;
    public minWidth: number;
    public width: number;
    public fieldType: ChoiceFieldTypes;
    public options: ChoiceOption[];
    public defaultValue: string;
    public layout: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;
    public showFilter: boolean;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = '[[[Wybór]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.options = [
            { value: '1', text: 'Primus...', selected: false },
            { value: '2', text: 'Secundus...', selected: false },
            { value: '3', text: 'Tetrium...', selected: false }
        ];
        this.layout = 0;
        this.defaultValue = '';
        this.help = '';
        this.fieldType = ChoiceFieldTypes.Dropdown;
        this.minWidth = 1;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
    }

    public createEntry(data: any): ChoiceEntry
    {
        return new ChoiceEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
