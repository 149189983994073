<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { Definition as RelatedSelfDefinition } from '../related-self';
import { RelatedParentEntry, instanceOfRelatedParentEntry, Definition, RelatedParentType } from '.';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { useLocalization } from '@/plugins/localization';
import EntriesService from '@/modules/studio/entries/services/EntriesService';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';

defineOptions({
    name: 'related-parent-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const { $t } = useLocalization();

const entryData = ref(new RelatedParentEntry()) as Ref<RelatedParentEntry>;
const limit = ref(10);

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRelatedParentEntry);

const model = computed({
    get(): Option<number>|Option<number>[]
    {
        if (props.blueprint.multiple)
        {
            return entryData.value.values ?? [];
        }
        else
        {
            return entryData.value.values?.first();
        }
    },
    set(model: Option<number>|Option<number>[])
    {
        if (props.blueprint.multiple)
        {
            entryData.value.values = model as Option<number>[];
        }
        else
        {
            entryData.value.values = model != null ? [model as Option<number>] : [];
        }
    }
});

const placeholder = computed(() => props.blueprint.placeholder || $t(`[[[wybierz...]]]`));
const required = computed(() => props.form.expressions.required(props.blueprint));

const components = computed<Option[]>(() =>
{
    return props.form.schema
        .components([Definition.type, RelatedSelfDefinition.type], props.blueprint)
        .map(p => ({ value: p.name, text: (p as HasLabel).label }));
});

const scope = computed<number[]>(() =>
{
    return components.value
        .where(p => props.blueprint.dependsOn.includes(p.value))
        .select(p => props.entry[p.value].values as Option<number>[])
        .where(p => p != null)
        .selectMany(p => p.select((q: any) => q.value).toArray())
        .toArray();
});

const disabled = computed(() =>
{
    if (props.blueprint.dependsOn.length > 0)
    {
        return props.blueprint.dependsOn.length != scope.value.length;
    }

    return false;
});

const values = computed<number|number[]>(() =>
{
    if (props.blueprint.multiple)
        return (model.value as Option<number>[]).select(p => p.value).toArray();
    else
        return (model.value as Option<number>)?.value;
});

const onChanged = (value: Option<number>|Option<number>[]): void =>
{
    model.value = value;
};

const fetch = async (id: number): Promise<Option<number>> =>
{
    return new Promise(resolve => resolve(entryData.value.values.where(p => p.value == id).first()));
};

const search = async (query: string): Promise<Option<number>[]> =>
{
    const pager = new Pager(1, limit.value, 'Title', 'ASC');
    const response = await EntriesService.getOptionsPublished(pager, props.blueprint.formId, scope.value, null, query);

    return response.items.map(p => ({
        value: p.value,
        text: p.text
    }));
};

watch(scope, (value: number[], old: number[]): void =>
{
    if (value.join('|') != old.join('|'))
    {
        model.value = null;
    }
});
</script>

<template>
    <form-component-wrapper class="related-parent-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :required="required" />
                <ideo-autocomplete :name="uid" :fetch="fetch" :search="search" :modelValue="values" :placeholder="placeholder" :multiple="blueprint.multiple" :disabled="disabled" @changed="onChanged" />
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-success p-2 pb-0">
                <h6>{{ $t('[[[Źródło danych]]]') }}</h6>
                <hr class="my-1">
                <field-category v-model="blueprint.categoryId" :label="$t('[[[Kategoria]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'categoryId')" />
                <field-form v-model="blueprint.formId" :category="blueprint.categoryId" :label="$t('[[[Formularz]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'formId')" :disabled="!blueprint.categoryId" />
            </div>
            <div class="alert alert-secondary p-2 pb-0" v-if="components.length > 0">
                <field-checkboxlist v-model="blueprint.dependsOn" :options="components" :label="$t('[[[Zależna od kontrolki]]]')" class="mb-0" />
            </div>
            <field-checkbox v-model="blueprint.multiple" :label="$t('[[[Możliwość wielokrotnego wyboru]]]')" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" :placeholder="$t('[[[wybierz...]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
