<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import { Option } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';

const { $t } = useLocalization();
const instance = getCurrentInstance();

defineOptions({
    name: 'field-checkboxlist'
});

const props = defineProps({
  "label": null,
  "options": null,
  "modelValue": { type: Boolean,  },
  "inline": { type: Boolean, default: false }
});

const emit = defineEmits(["update:modelValue"]);

const uid = computed(() => `form-field-${instance.uid}`);
const items = computed<Option<any>[]>(() =>
{
    return props.options.map(p =>
    {
        return { value: p.value, text: $t(p.text) };
    });
});


const triggerInput = (value: any): void =>
{
    emit('update:modelValue', value);
};

const selected = computed({
    get(): any
    {
        return props.modelValue;
    },
    set(value: any)
    {
        triggerInput(value);
    }
});
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <ideo-form-checkbox-group name="components" v-model="selected" :options="items" stacked></ideo-form-checkbox-group>
        </div>
    </div>
</template>
