import { Directive, Plugin } from 'vue';
// import iFrameResize from 'iframe-resizer/js/iframeResizer';

const DirectivesPlugin: Plugin =
{
    install(app, options)
    {
        app.directive('slugify', {
            beforeMount: (el: any, binding: any, vnode: any) =>
            {
                const search = [" ", "ę", "ó", "ą", "ś", "ł", "ż", "ź", "ć", "ń"];
                const replace = ["-", "e", "o", "a", "s", "l", "z", "z", "c", "n"];

                el.slugify = function(value: string)
                {
                    let text = value.toLowerCase();

                    for (let i = 0; i < search.length; i++)
                    {
                        const re = new RegExp(search[i], "g");

                        text = text.replace(re, replace[i]);
                    }

                    return text.replace(/[^a-z0-9-]/g, "");
                };

                el.addEventListener('blur', function(e: any)
                {
                    e.target.value = el.slugify(e.target.value);
                    e.target.dispatchEvent(new Event('input', { bubbles: true }));
                });
            }
        } as Directive);

        app.directive('focus', {
            mounted: function(el: HTMLElement)
            {
                el.dataset.focus = 'true';
                el.focus();
            }
        } as Directive);
    }
};

export default DirectivesPlugin;
