import { $t } from '@/plugins/localization';
import { Option } from './Interfaces';

enum PublicationEnum {
    Draft = 'Draft',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Accepted = 'Accepted'
}

const PublicationOptions = (): Option[] => ([
    { value: PublicationEnum.Draft, text: $t('[[[Szkic]]]'), icon: 'far fa-fw fa-circle text-secondary' },
    { value: PublicationEnum.Finished, text: $t('[[[Do akceptacji]]]'), icon: 'far fa-fw fa-dot-circle text-info' },
    { value: PublicationEnum.Rejected, text: $t('[[[Zwrócony]]]'), icon: 'far fa-fw fa-times-circle text-danger' },
    { value: PublicationEnum.Accepted, text: $t('[[[Zaakceptowany]]]'), icon: 'far fa-fw fa-check-circle text-success' }
]);

const AllPublicationOptions = (): Option[] => (
    [ { value: null, text: $t('[[[Wszystkie]]]') } ].concat(PublicationOptions())
);

const PublicationName = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.text || PublicationEnum[value];
const PublicationIcon = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.icon || '';
const PublicationStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'secondary')] = status === PublicationEnum.Draft;
    classes[classTemplate.replace(re, 'info')] = status === PublicationEnum.Finished;
    classes[classTemplate.replace(re, 'danger')] = status === PublicationEnum.Rejected;
    classes[classTemplate.replace(re, 'success')] = status === PublicationEnum.Accepted;

    return classes;
};

export {
    PublicationEnum,
    PublicationOptions,
    PublicationName,
    PublicationStyle,
    PublicationIcon,
    AllPublicationOptions
};

// ----------------------------------------------------------------------------------------------------

enum EntityState {
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added'
}

const EntityStateOptions = (): Option[] => ([
    { value: EntityState.Deleted, text: $t('[[[Usunięcie]]]') },
    { value: EntityState.Modified, text: $t('[[[Modyfikacja]]]') },
    { value: EntityState.Added, text: $t('[[[Dodanie]]]') }
]);

const EntityStateName = (value: EntityState): string => EntityStateOptions().find(p => p.value === value)?.text || EntityState[value];

export {
    EntityState,
    EntityStateOptions,
    EntityStateName
};

// ----------------------------------------------------------------------------------------------------

enum AccountType {
    Local = 'Local',
    Ldap = 'Ldap'
}

export {
    AccountType
};

// ----------------------------------------------------------------------------------------------------

enum DictionaryType {
    CoreUsers = 'CoreUsers',
    CoreRoles = 'CoreRoles',
    CoreTeams = 'CoreTeams',

    // CoreJobPositions = 'CoreJobPositions',
    // CoreCountries = 'CoreCountries',
    // CoreCities = 'CoreCities',
    // CoreProvinces = 'CoreProvinces',
    // ContactsEventTypes = 'ContactsEventTypes',
    // CalendarResourceCategories = 'CalendarResourceCategories'
}

const DictionaryTypeOptions = (): Option[] => ([
    { value: DictionaryType.CoreUsers, text: $t('[[[Administracja - Użytkownicy]]]') },
    { value: DictionaryType.CoreRoles, text: $t('[[[Administracja - Role]]]') },
    { value: DictionaryType.CoreTeams, text: $t('[[[Administracja - Zespoły]]]') }

    // { value: DictionaryType.CoreJobPositions, text: $t('[[[Administracja - Stanowiska pracy]]]') },
    // { value: DictionaryType.CoreCountries, text: $t('[[[Administracja - Kraje]]]') },
    // { value: DictionaryType.CoreCities, text: $t('[[[Administracja - Miasta]]]') },
    // { value: DictionaryType.CoreProvinces, text: $t('[[[Administracja - Województwa]]]') },
    // { value: DictionaryType.ContactsEventTypes, text: $t('[[[Kontakty - Typ działania]]]') },
    // { value: DictionaryType.CalendarResourceCategories, text: $t('[[[Kalendarz - Kategorie zasobów]]]') }
]);

const DictionaryTypeName = (value: DictionaryType): string => DictionaryTypeOptions().find(p => p.value === value)?.text || DictionaryType[value];

export {
    DictionaryType,
    DictionaryTypeOptions,
    DictionaryTypeName
};

// ----------------------------------------------------------------------------------------------------

enum QueueStage {
    DISPATCH = 100,
    QUEUED = 200,
    DELAYED = 300,
    RETRY = 400,
    REJECTED = 500,
    PROCESSING = 600,
    SUCCEEDED = 700,
    FAILED = 800
}

const QueueStageOptions = (): Option<QueueStage>[] => ([
    { value: QueueStage.DISPATCH, text: $t('[[[Wysłane]]]') },
    { value: QueueStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: QueueStage.DELAYED, text: $t('[[[Zaplanowane]]]'), icon: 'fad fa-clock text-info' },
    { value: QueueStage.RETRY, text: $t('[[[Powtórzone]]]') },
    { value: QueueStage.REJECTED, text: $t('[[[Odrzucone]]]') },
    { value: QueueStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: QueueStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: QueueStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const QueueStageName = (value: QueueStage): string => QueueStageOptions().find(p => p.value === value)?.text || QueueStage[value];

export {
    QueueStage,
    QueueStageOptions,
    QueueStageName
};

// ----------------------------------------------------------------------------------------------------

enum JobStage {
    QUEUED = 210,
    PROCESSING = 610,
    SUCCEEDED = 620,
    FAILED = 630
}

const JobStageOptions = (): Option<JobStage>[] => ([
    { value: JobStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: JobStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: JobStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: JobStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const JobStageName = (value: JobStage): string => JobStageOptions().find(p => p.value === value)?.text || JobStage[value];

export {
    JobStage,
    JobStageOptions,
    JobStageName
};
