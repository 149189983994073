import { Blueprint } from "./Blueprint";
import { AggregateBlueprint, instanceOfAggregateBlueprint } from "./AggregateBlueprint";

export interface SlotBlueprint extends AggregateBlueprint
{
    kind: 'slot';
}

export interface SlotConstructor
{
    new (id: string, name: string): SlotBlueprint;
}

export const instanceOfSlotBlueprint = (object: any): object is SlotBlueprint =>
{
    return instanceOfAggregateBlueprint(object) && 'kind' in object && object.kind === 'slot';
};

export abstract class SlotAbstract implements SlotBlueprint
{
    public kind: 'slot' = 'slot' as const;
    public id: string;
    public type: string;
    public name: string;
    public disabled?: boolean = false;
    public components: Blueprint[] = [];

    public constructor(id: string, name: string, type: string)
    {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}
