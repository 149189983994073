<script lang="ts" setup>
import { computed, watch, reactive, getCurrentInstance } from 'vue';
import properties from '../../properties';
import { PageContract } from '../page';
import { instanceOfTextEntry, TextContract } from '../text';
import { FormContract, FormEntry, ProgressChoice, TabsType } from '.';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';

defineOptions({
    name: 'form-blueprint',
    components: {
        ...properties
    }
});

const instance = getCurrentInstance();

const props = defineProps({
  "builder": null
});
const emit = defineEmits(["submit"]);

const form = computed<FormBuilderContract>(() => reactive(props.builder) as FormBuilderContract);
const blueprint = computed<FormContract>(() => reactive(props.builder.blueprint) as FormContract);
const entry = computed<FormEntry>(() => reactive(props.builder.entry) as FormEntry);

if (blueprint.value.components.length == 0)
{
    form.value.layout.addArea(blueprint.value);
}

const design = computed(() => props.builder.design);
const selected = computed(() => form.value.clipboard.isSelected(blueprint.value));

const pages = computed<PageContract[]>(() =>
{
    return (blueprint.value.components as PageContract[]).filter(p =>
    {
        return design.value || form.value.expressions.visible(p);
    });
});

const progressChoice = computed(() => ProgressChoice);
const progressTypeOptions: Option<ProgressChoice>[] = [
    { value: ProgressChoice.Steps, text: '[[[Kroki]]]' },
    { value: ProgressChoice.Bar, text: '[[[Pasek]]]' },
    { value: ProgressChoice.Tabs, text: '[[[Zakładki]]]' },
    { value: ProgressChoice.None, text: '[[[Brak]]]' }
];

const tabsType = computed(() => TabsType);
const tabsTypeOptions: Option<TabsType>[] = [
    { value: TabsType.Tabs, text: '[[[Domyślny]]]' },
    { value: TabsType.Pills, text: '[[[Pigułki]]]' },
    { value: TabsType.Underline, text: '[[[Podkreślenie]]]' }
];

const headerVisible = computed(() => (
    design.value ||
    blueprint.value.showTitle ||
    !!blueprint.value.description ||
    pages.value.length > 1 ||
    blueprint.value.progressType == ProgressChoice.None.toString()
));

const textComponents = computed<TextContract[]>(() =>
{
    return form.value.schema.components()
        .filter(p => instanceOfTextEntry(p))
        .map((p: any) => p as TextContract);
});

const textOptions = computed<Option[]>(() => ([
    {value: null, text: ''},
    ...textComponents.value.map(p => ({value: p.id, text: p.label}))
]));

const headerValue = computed({
    get()
    {
        return textComponents.value.find(p => p.isHeader)?.id;
    },
    set(id: string)
    {
        textComponents.value.forEach(p => p.isHeader = false);
        textComponents.value.filter(p => p.id == id).forEach(p => p.isHeader = true);
    }
});

const summaryValue = computed({
    get()
    {
        return textComponents.value.find(p => p.isSummary)?.id;
    },
    set(id: string)
    {
        textComponents.value.forEach(p => p.isSummary = false);
        textComponents.value.filter(p => p.id == id).forEach(p => p.isSummary = true);
    }
});

const addPage = (before: PageContract): void =>
{
    form.value.layout.addArea(blueprint.value, before);
};

const selectForm = (): void =>
{
    form.value.clipboard.selectDocument(blueprint.value);
};

const currentPage = (page: PageContract): boolean =>
{
    return blueprint.value.components[blueprint.value.currentPage - 1] == page;
};

const setPage = (page: PageContract): void =>
{
    blueprint.value.currentPage = blueprint.value.components.indexOf(page) + 1;
};

const submit = (): void =>
{
    emit('submit');
};

watch(selected, (value: boolean): void =>
{
    if (value)
    {
        form.value.dom.offsetTop(instance);
    }
});

watch(blueprint, (value: Blueprint): void =>
{
    selectForm();
}, { immediate: true });
</script>

<template>
    <form-component :class="{'form-component': true, 'design': design, 'selected': selected}" tag="fieldset" @click.stop="selectForm()" :form="form" :blueprint="blueprint">
        <div class="form-component-header" :class="{'design': design}" v-if="headerVisible">
            <h1 v-if="blueprint.showTitle || design" :class="{'text-secondary': !blueprint.showTitle}">
                {{ $t(blueprint.title) || '...' }}
            </h1>
            <p class="description" v-if="blueprint.description">{{ blueprint.description }}</p>
            <ol class="list-inline" v-if="pages.length > 1 && blueprint.progressType == progressChoice.Steps">
                <li
                    class="list-inline-item border-bottom border-2 px-5 py-2 my-0 me-1"
                    :class="{
                        'border-primary text-primary': currentPage(page),
                        'border-secondary text-muted': !currentPage(page)
                    }"
                    v-for="(page, index) in pages" :key="page.id"
                >
                    <span class="font-weight-bold">{{ index + 1 }}.</span>
                    <span class="ms-2">{{ page.title || $t('[[[Strona %0|||{0}]]]', index + 1) }}</span>
                </li>
            </ol>
            <ul class="nav nav-pills nav-justified mx-n1" v-if="pages.length > 1 && blueprint.progressType == progressChoice.Bar">
                <li class="nav-item px-1" v-for="(page, index) in pages" :key="page.id">
                    <span class="nav-link rounded-pill p-1" :class="{'active': currentPage(page), 'bg-light-subtle text-body': !currentPage(page)}">
                        <span v-if="blueprint.showPageTitles">{{ page.title || $t('[[[Strona %0|||{0}]]]', index + 1) }}</span>
                    </span>
                </li>
            </ul>
            <ul
                class="nav"
                :class="{
                    'nav-tabs': blueprint.tabsType == tabsType.Tabs,
                    'nav-pills': blueprint.tabsType == tabsType.Pills,
                    'nav-underline': blueprint.tabsType == tabsType.Underline,
                    'nav-justified': blueprint.tabsJustified
                }"
                v-if="pages.length > 1 && blueprint.progressType == progressChoice.Tabs"
            >
                <li class="nav-item" v-for="(page, index) in pages" :key="page.id" @click="setPage(page)">
                    <span class="nav-link" :class="{'active': currentPage(page)}">
                        <span>{{ page.title || $t('[[[Strona %0|||{0}]]]', index + 1) }}</span>
                    </span>
                </li>
            </ul>
        </div>
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <design-ruller :title="$t('[[[Wstaw stronę]]]')" :form="form" :before="component" @insert="addPage" />
            <component :is="form.schema.designer(component.type)" :form="form" :parent="blueprint" :blueprint="blueprint.components[index]" :entry="entry" @submit="submit()" />
        </template>
        <div class="form-component-footer" v-if="design">
            <div class="d-grid">
                <button class="btn btn-light no-shadow" :title="$t('[[[Dodaj stronę]]]')" @click.stop.prevent="form.layout.addArea(blueprint)">
                    <i class="fa fa-plus me-2"></i>
                </button>
            </div>
        </div>
        <portal to="form-toolbox" v-if="design && selected">
            <design-panel :form="form" :key="blueprint.name">
                <h3>{{ $t('[[[Formularz]]]') }}</h3>
                <hr>
                <field-text v-model="blueprint.title" :label="$t('[[[Tytuł]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'title')" />
                <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł formularza]]]')" />
                <field-category v-model="blueprint.category" :label="$t('[[[Kategoria]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'category')" />
                <field-textarea v-model="blueprint.description" :label="$t('[[[Opis]]]')" />
                <field-choice v-model="blueprint.progressType" :options="progressTypeOptions" :label="$t('[[[Pasek postępu]]]')" v-if="blueprint.components.length > 1" />
                <field-checkbox v-model="blueprint.showPageTitles" :label="$t('[[[Pokaż tytuły stron]]]')" v-if="blueprint.components.length > 1 && blueprint.progressType == progressChoice.Bar" />
                <field-choice v-model="blueprint.tabsType" :options="tabsTypeOptions" :label="$t('[[[Sposób prezentacji zakładek]]]')" v-if="blueprint.components.length > 1 && blueprint.progressType == progressChoice.Tabs" />
                <field-checkbox v-model="blueprint.tabsJustified" :label="$t('[[[Rozmieść równomiernie]]]')" v-if="blueprint.components.length > 1 && blueprint.progressType == progressChoice.Tabs" />
                <field-checkbox v-model="blueprint.showPageNumbers" :label="$t('[[[Pokaż numery stron]]]')" v-if="blueprint.progressType != progressChoice.Tabs" />
                <field-checkbox v-model="blueprint.showSendButton" :label="$t('[[[Pokaż przycisk ``Wyślij``]]]')" />
                <field-text v-model="blueprint.sendButtonText" :label="$t('[[[Nazwa przycisku ``Wyślij``]]]')" :placeholder="$t('[[[Wyślij]]]')" v-if="blueprint.showSendButton" />
                <h3>{{ $t('[[[Dane]]]') }}</h3>
                <hr>
                <field-select v-model="headerValue" :options="textOptions" :label="$t('[[[Nagłówek]]]')" />
                <field-select v-model="summaryValue" :options="textOptions" :label="$t('[[[Krótki opis]]]')" />
            </design-panel>
        </portal>
    </form-component>
</template>

<style lang="scss" scoped>
.form-component {
    &.design {
        padding: 10px;
        margin: -10px;
        border: 2px solid transparent;
    }

    &.selected {
        border-color: var(--bs-success);
    }

    .form-component-header {
        margin-bottom: 20px;

        &.design {
            margin: 0 10px 20px;
        }
    }

    .form-component-footer {
        margin: 5px 0 0;
    }

    .description {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: justify;
    }
}
</style>
