<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import { FormEntry } from '../form';
import properties from '../../properties';
import { AttachmentsEntry, AttachmentsType, instanceOfAttachmentsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'attachments-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new AttachmentsEntry()) as Ref<AttachmentsEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfAttachmentsEntry);

const value = computed({
    get()
    {
        return entryData.value.values;
    },
    set(values: any[])
    {
        if (values == null)
            values = [];

        if (!Array.isArray(values))
            values = [values];

        entryData.value.values = values;
    }
});

const required = computed(() => props.form.expressions.required(props.blueprint));
const extensions = computed(() =>
{
    return props.blueprint.extensions.split(',').map(p => p.trim()).filter(p => !!p);
});
</script>

<template>
    <form-component-wrapper class="attachments-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :required="required" />
                <fileupload :id="blueprint.id" v-model="value" :max-size="blueprint.maxFileSize" :max-files="blueprint.maxFiles" :extensions="extensions" :dark="false"></fileupload>
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-text v-model="blueprint.extensions" :label="$t('[[[Dozwolone rozszerzenia]]]')" placeholder="jpg, jpeg, png" />
            <field-numeric v-model="blueprint.maxFileSize" :label="$t('[[[Maksymalny rozmiar pliku w MB]]]')" :min="0" :max="100" />
            <field-numeric v-model="blueprint.maxFiles" :label="$t('[[[Maksymalna ilość plików]]]')" :min="1" :max="20" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
