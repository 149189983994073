// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { AggregateBlueprint } from '../blueprints/AggregateBlueprint';
import { DocumentBlueprint, instanceOfDocumentBlueprint } from '../blueprints/DocumentBlueprint';
import { AreaBlueprint } from '../blueprints/AreaBlueprint';
import { SlotBlueprint } from '../blueprints/SlotBlueprint';

// Managers
import { EventManager, Events } from '../managers/EventManager';
import { SchemaManager } from './SchemaManager';
import { DimensionsManager } from './DimensionsManager';

// Services
import { BlueprintFactory } from '../services/BlueprintFactory';

// --------------------------------------------------

export class LayoutManager
{
    protected factory: BlueprintFactory;
    protected events: EventManager;
    protected schema: SchemaManager;
    protected dimensions: DimensionsManager;

    public constructor(blueprintFactory: BlueprintFactory, eventManager: EventManager, schemaManager: SchemaManager, dimensionsManager: DimensionsManager)
    {
        this.factory = blueprintFactory;
        this.events = eventManager;
        this.schema = schemaManager;
        this.dimensions = dimensionsManager;
    }

    public addArea(parent: DocumentBlueprint, before: AreaBlueprint = null): AreaBlueprint
    {
        const area = this.factory.createArea(this.schema.newId(), 'area');

        area.name = this.schema.name(area.type);

        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, area);
        else
            parent.components.push(area);

        return area;
    }

    public addSlot(parent: AggregateBlueprint, before: SlotBlueprint = null): SlotBlueprint
    {
        const slot = this.factory.createSlot(this.schema.newId(), 'slot');

        slot.name = this.schema.name(slot.type);

        if (before != null)
        {
            parent.components.splice(parent.components.indexOf(before), 0, slot);
            this.events.emit(Events.FOCUS, slot);
        }
        else
        {
            parent.components.push(slot);
        }

        return slot;
    }

    public addSlotBefore(before: SlotBlueprint): void
    {
        this.addSlot(this.schema.parent(before), before);
    }

    public addSlotAfter(after: SlotBlueprint): void
    {
        const next = this.schema.next(after);

        if (next != null)
        {
            if ((next as any as SlotBlueprint).components.length > 0)
                this.addSlot(this.schema.parent(after), next as any as SlotBlueprint);
            else
                this.events.emit(Events.FOCUS, next);
        }
    }

    public createComponent(parent: AggregateBlueprint, type: string): Blueprint
    {
        return this.factory.create(
            this.schema.typeName(type),
            this.schema.newId(),
            this.schema.name(type),
            this.dimensions.space(parent)
        );
    }

    public insertComponent(parent: AggregateBlueprint, component: Blueprint, before: Blueprint = null): void
    {
        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, component);
        else
            parent.components.push(component);

        this.dimensions.autoarrange(parent, false);
    }

    public addComponent(parent: AggregateBlueprint, type: string, before: Blueprint = null): Blueprint
    {
        // utworzenie nowego obiektu danego typu po nazwie
        const component: any = this.createComponent(parent, type);

        if (this.dimensions.available(parent) >= component.width)
        {
            this.insertComponent(parent, component, before);
            this.events.emit(Events.FOCUS, component);

            return component;
        }

        return null;
    }

    public updateComponent(component: Blueprint, type: string): Blueprint
    {
        const replacement = this.createComponent(this.schema.parent(component), type);

        this.insertComponent(this.schema.parent(component), replacement, component);
        this.removeComponent(component);

        return replacement;
    }

    public removeComponent(component: Blueprint): void
    {
        const parent = this.schema.parent(component);

        parent.components = parent.components.filter(p => p !== component);
    }

    public addBefore(component: Blueprint): void
    {
        const parent = this.schema.parent(component);

        this.events.emit(Events.INSERT, parent, component);
    }

    public addAfter(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const next = this.schema.next(component);

        this.events.emit(Events.INSERT, parent, next);
    }

    public moveUp(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const index = parent.components.indexOf(component);

        if (index > 0)
        {
            parent.components.splice(index, 1);
            parent.components.splice(index - 1, 0, component);
        }
    }

    public moveDown(component: Blueprint): void
    {
        const parent = this.schema.parent(component);
        const index = parent.components.indexOf(component);
        const length = parent.components.length - (instanceOfDocumentBlueprint(parent) ? 0 : 1);

        if (index + 1 < length)
        {
            parent.components.splice(index + 2, 0, component);
            parent.components.splice(index, 1);
        }
    }
}
