import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource } from '@/helpers/Interfaces';

export default class CategoriesService
{
    public static async getCategories(filter: CategoryFilterModel, pager: Pager): Promise<Pagination<CategoryItemModel>>
    {
        return (await axios.get<Pagination<CategoryItemModel>>(`admin/forms/categories`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    public static async fetchCategory(id: number): Promise<Resource<CategoryModel>>
    {
        return (await axios.get<Resource<CategoryModel>>(`admin/forms/categories/${id}`)).data;
    }

    public static async addCategory(model: CategoryModel): Promise<CategoryModel>
    {
        return (await axios.post<CategoryModel>(`admin/forms/categories`, model)).data;
    }

    public static async updateCategory(model: CategoryModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/forms/categories/${model.id}`, model)).data;
    }

    public static async removeCategory(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/forms/categories/${id}`)).data as Statement;
    }
}

export interface CategoryFilterModel
{
    name: string;
}

export interface CategoryItemModel
{
    id: number;
    name: string;
    position: number;
}

export interface CategoryModel
{
    id: number;
    name: string;
    position: number;
}
