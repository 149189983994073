<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { instanceOfPersonalEntry, PersonalType, PersonalEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'personal-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new PersonalEntry()) as Ref<PersonalEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfPersonalEntry);

const title = computed({
    get()
    {
        return entryData.value.title;
    },
    set(value: string)
    {
        entryData.value.title = value;
    }
});

const givenName = computed({
    get()
    {
        return entryData.value.givenName;
    },
    set(value: string)
    {
        entryData.value.givenName = value;
    }
});

const middleName = computed({
    get()
    {
        return entryData.value.middleName;
    },
    set(value: string)
    {
        entryData.value.middleName = value;
    }
});

const surname = computed({
    get()
    {
        return entryData.value.surname;
    },
    set(value: string)
    {
        entryData.value.surname = value;
    }
});

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
</script>

<template>
    <form-component-wrapper class="personal-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :required="required" />
                <div class="row g-0">
                    <div class="col-2 pe-2" v-if="blueprint.hasTitle">
                        <input type="text" class="form-control" v-model="title" :placeholder="$t('[[[Tytuł]]]')" :readonly="readonly">
                    </div>
                    <div class="col pe-2">
                        <input type="text" class="form-control" v-model="givenName" :placeholder="$t('[[[Imię]]]')" :readonly="readonly">
                    </div>
                    <div class="col pe-2" v-if="blueprint.hasMiddleName">
                        <input type="text" class="form-control" v-model="middleName" :placeholder="$t('[[[Drugie imię]]]')" :readonly="readonly">
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="surname" :placeholder="$t('[[[Nazwisko]]]')" :readonly="readonly">
                    </div>
                </div>
                <form-error-message :entry="entryData" name="title" />
                <form-error-message :entry="entryData" name="givenName" />
                <form-error-message :entry="entryData" name="middleName" />
                <form-error-message :entry="entryData" name="surname" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <h6>{{ $t('[[[Pole zawiera]]]') }}</h6>
            <field-checkbox v-model="blueprint.hasTitle" :label="$t('[[[Tytuł]]]')" />
            <field-checkbox v-model="blueprint.hasMiddleName" :label="$t('[[[Drugie imię]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
