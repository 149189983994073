import { Entry, instanceOfEntry } from "./Entry";
import { ValidEntry } from "./ValidEntry";

export interface RootEntry extends Entry
{
    [key: string]: Entry|any;
}

export const instanceOfRootEntry = (object: any): object is Entry =>
{
    return instanceOfEntry(object) && Object.keys(object).length >= 1;
};

export abstract class RootEntryAbstract extends ValidEntry implements RootEntry
{
    public type: string;

    public constructor(type: string)
    {
        super();

        this.type = type;
    }
}
