// Entries
import { Entry } from '../form/entries/Entry';

// Managers
import { DocumentManager } from './managers/DocumentManager';
import { ExpressionManager } from './managers/ExpressionManager';

// Services
import { BlueprintFactory } from '../base/services/BlueprintFactory';

// Base
import { ContentBuilder, ContentBuilderContract } from '../base';
import { DomManager } from './managers/DomManager';
import { instanceOfValidEntry } from './entries/ValidEntry';
import { ProcessCallback } from './types/ProcessCallback';
import { AggregateBlueprint } from '../base/blueprints/AggregateBlueprint';
import { BlueprintDefinition } from './blueprints/BlueprintDefinition';
import { Blueprint } from '../base/blueprints/Blueprint';

// --------------------------------------------------

export interface FormBuilderContract extends ContentBuilderContract
{
    readonly document: DocumentManager;
    readonly expressions: ExpressionManager;
    readonly dom: DomManager;

    readonly entryId: number;
    readonly entry: Entry;
    getEntry(): Entry;

    validateEntry(): boolean;
    setEntryErrors(errors: Record<string, string[]>): void;
    collectEntry(preprocess: ProcessCallback): Promise<Entry>;
    addComponent(parent: AggregateBlueprint, definition: BlueprintDefinition, before?: Blueprint): Blueprint
}

export class FormBuilder extends ContentBuilder implements FormBuilderContract
{
    private entryFactory: () => Entry;

    private documentManager: DocumentManager;
    private expressionManager: ExpressionManager;
    private domManager: DomManager;

    public constructor(blueprintFactory: BlueprintFactory)
    {
        super(blueprintFactory);

        const documentManager = new DocumentManager(
            this.schema
        );
        const expressionManager = new ExpressionManager(
            this.state,
            this.schema,
            documentManager
        );
        const domManager = new DomManager(
            this.schema
        );

        this.documentManager = documentManager;
        this.expressionManager = expressionManager;
        this.domManager = domManager;
    }

    // --------------------------------------------------

    public get document(): DocumentManager
    {
        return this.documentManager;
    }

    public get expressions(): ExpressionManager
    {
        return this.expressionManager;
    }

    public get dom(): DomManager
    {
        return this.domManager;
    }

    // --------------------------------------------------

    public get entry(): Entry
    {
        return this.document.getRootEntry();
    }

    public set entry(entry: Entry)
    {
        this.document.setRootEntry(entry);
    }

    public getEntry(): Entry
    {
        return this.entry;
    }

    public setEntry(entry: () => Entry): void
    {
        this.entryFactory = entry;
    }

    public get entryId(): number
    {
        return this.document.id;
    }

    public setEntryIdentity(callback: () => number): void
    {
        this.document.setIdentity(callback);
    }

    public validateEntry(): boolean
    {
        if (instanceOfValidEntry(this.entry))
        {
            return this.entry.validate(this.blueprint, this);
        }

        return true;
    }

    public setEntryErrors(errors: Record<string, string[]>): void
    {
        this.document.setErrors(errors);
    }

    public async collectEntry(preprocess: ProcessCallback): Promise<Entry>
    {
        if (instanceOfValidEntry(this.entry))
        {
            return await this.entry.collect(this.blueprint, this, preprocess);
        }

        return this.entry;
    }

    // --------------------------------------------------

    public update(): void
    {
        super.update();

        this.entry = this.entryFactory();
    }

    public addComponent(parent: AggregateBlueprint, definition: BlueprintDefinition, before: Blueprint = null): Blueprint
    {
        const component = this.layout.addComponent(parent, definition.type, before);

        if (component && 'composite' in component && definition.key)
        {
            component.composite = definition.key;
        }

        return component;
    }
}
