import cloneDeepWith from 'lodash/cloneDeepWith';

// Blueprints
import { Blueprint } from '../blueprints/Blueprint';
import { AggregateBlueprint } from '../blueprints/AggregateBlueprint';
import { DocumentBlueprint } from '../blueprints/DocumentBlueprint';
import { AreaBlueprint } from '../blueprints/AreaBlueprint';
import { SlotBlueprint, instanceOfSlotBlueprint } from '../blueprints/SlotBlueprint';

// Traits
import { HasWidth } from '../traits/HasWidth';

// Managers
import { EventManager, Events } from './EventManager';
import { SchemaManager } from './SchemaManager';
import { DimensionsManager } from './DimensionsManager';
import { LayoutManager } from './LayoutManager';

// --------------------------------------------------

type ClipboardMode = 'none'|'copy'|'cut';
type TargetType = 'none'|'document'|'area'|'slot'|'component';

export class ClipboardManager
{
    private mode: ClipboardMode = 'none';
    private targetType: TargetType = 'none';
    private target: Blueprint = null;
    private source: Blueprint = null;
    private placeholder: Blueprint = null;

    private events: EventManager;
    private schema: SchemaManager;
    private dimensions: DimensionsManager;
    private layout: LayoutManager;

    public constructor(eventManager: EventManager, schemaManager: SchemaManager, dimensionsManager: DimensionsManager, layoutManager: LayoutManager)
    {
        this.events = eventManager;
        this.schema = schemaManager;
        this.dimensions = dimensionsManager;
        this.layout = layoutManager;

        this.events.subscribe(Events.FOCUS, (blueprint: Blueprint) =>
        {
            this.selectComponent(blueprint);
        });
        this.events.subscribe(Events.INSERT, (parent: AggregateBlueprint, component: Blueprint) =>
        {
            this.selectPlaceholder(parent as SlotBlueprint, component);
        });
    }

    public getTarget(): Blueprint
    {
        return this.target;
    }

    public getSource(): Blueprint
    {
        return this.source;
    }

    public copy(component: Blueprint): void
    {
        this.source = component;
        this.mode = 'copy';
    }

    public cut(component: Blueprint): void
    {
        this.source = component;
        this.mode = 'cut';
    }

    public paste(parent: AggregateBlueprint, before: Blueprint = null): void
    {
        if (this.mode == 'copy' && this.source != null && this.canPaste(parent))
        {
            const names: Record<string, string[]> = {};
            const component = cloneDeepWith(this.source, (value, key, item) =>
            {
                if (key == 'id')
                    return this.schema.newId();

                if (key == 'name')
                {
                    names[item.type] = names[item.type] || this.schema.names(item.type);

                    const name = this.schema.name(item.type, names[item.type]);

                    names[item.type].push(name);

                    return name;
                }
            });

            this.layout.insertComponent(parent, component, before);
            this.selectComponent(component);
        }

        if (this.mode == 'cut' && this.source != null && this.canPaste(parent))
        {
            this.layout.removeComponent(this.source);
            this.layout.insertComponent(parent, this.source, before);
            this.selectComponent(this.source);
        }

        if (instanceOfSlotBlueprint(parent) && this.dimensions.space(parent) < 0)
        {
            this.dimensions.autoarrange(parent);
        }

        this.source = null;
        this.mode = 'none';
    }

    public isCopy(component: Blueprint): boolean
    {
        return this.mode == 'copy' && this.source?.id == component.id;
    }

    public isCut(component: Blueprint): boolean
    {
        return this.mode == 'cut' && this.source?.id == component.id;
    }

    public canPaste(parent: AggregateBlueprint): boolean
    {
        if (this.mode != 'none' && instanceOfSlotBlueprint(parent) && this.source != null)
        {
            if (this.mode == 'copy' && (this.source as HasWidth).minWidth <= this.dimensions.available(parent) && this.schema.parent(parent, this.source.type) === null)
            {
                return true;
            }

            if (this.mode == 'cut' && this.dimensions.available(parent, this.source) >= 0 && this.schema.parent(parent, this.source.type) === null)
            {
                return true;
            }
        }

        return false;
    }

    public selectDocument(document: DocumentBlueprint): void
    {
        this.selectComponent(document);
    }

    public isDocumentSelected(document: DocumentBlueprint = null): boolean
    {
        return this.isComponentSelected(document || this.target) && this.targetType == 'document';
    }

    public selectArea(area: AreaBlueprint): void
    {
        this.selectComponent(area);
    }

    public isAreaSelected(page: AreaBlueprint = null): boolean
    {
        return this.isComponentSelected(page || this.target) && this.targetType == 'area';
    }

    public selectSlot(slot: SlotBlueprint): void
    {
        this.selectComponent(slot);
    }

    public isSlotSelected(slot: SlotBlueprint = null): boolean
    {
        return this.isComponentSelected(slot || this.target) && this.targetType == 'slot';
    }

    public selectPlaceholder(slot: SlotBlueprint, component: Blueprint): void
    {
        if (this.dimensions.available(slot) > 0)
        {
            if (this.dimensions.space(slot) == 0)
            {
                this.dimensions.rearrange(slot);
            }

            this.selectSlot(slot);
            this.placeholder = component;
        }
    }

    public isPlaceholderSelected(slot: SlotBlueprint, component: Blueprint): boolean
    {
        return this.isSlotSelected(slot) && ((this.placeholder == null && component == null) || (this.placeholder?.id == component?.id));
    }

    public selectComponent(component: Blueprint): void
    {
        this.targetType = 'kind' in component ? component.kind as TargetType : 'component';
        this.target = component;
        this.placeholder = null;
    }

    public isComponentSelected(component: Blueprint): boolean
    {
        return this.target?.id == component.id;
    }

    public isSelected(blueprint: Blueprint): boolean
    {
        return this.isComponentSelected(blueprint);
    }
}
