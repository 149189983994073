<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { axios } from '@/plugins/axios';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-autocomplete'
});



const props = defineProps({
  "label": null,
  "modelValue": null,
  "fetch": { type: Function, default: null },
  "search": { type: Function, default: null },
  "invalidFeedback": { type: Function, default: null },
  "endpoint": { default: null },
  "disabled": { type: Boolean, default: false },
  "valueField": { default: 'value' },
  "textField": { default: 'text' },
  "sortBy": { default: 'Text' }
});

const emit = defineEmits(["update:modelValue"]);

const uid = computed(() => `form-field-${instance.uid}`);

const triggerUpdate = (value: number): void =>
{
    emit('update:modelValue', value);
};

const model = computed({
    get(): number
    {
        return props.modelValue;
    },
    set(value: number)
    {
        triggerUpdate(value);
    }
});

const fetchOption = async (id: number): Promise<Option> =>
{
    if (props.fetch)
        return props.fetch(id);

    const { data } = await axios.get(`${props.endpoint}/${id}`);

    if (data)
    {
        return {
            value: data.result[props.valueField].toString(),
            text: data.result[props.textField]
        };
    }

    return null;
};

const searchOptions = async (query: string, limit: number): Promise<Option[]> =>
{
    if (props.search)
        return props.search(query, limit);

    const pager = new Pager(1, limit, props.sortBy, 'ASC');
    const { data } = (await axios.get(props.endpoint, {
        params: {...{ query }, ...pager.data()}
    }));

    return data.items.map((p: any) =>
    {
        return {
            value: p[props.valueField].toString(),
            text: p[props.textField]
        };
    });
};
</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <ideo-autocomplete
            v-model="model"
            :fetch="(id: number) => fetchOption(id)"
            :search="(query: string, limit: number) => searchOptions(query, limit)"
            :disabled="disabled"
            :refresh="true"
        />
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>
