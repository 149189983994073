<template>
    <div id="account" class="d-flex align-self-center">
        <ideo-dropdown ref="menu" variant="link" :toggle-class="{'navbar-toggler': true, 'active': visible}" right no-caret @toggle="onToggle">
            <template #button-content>
                <ideo-img :src="$filters.image(facePictureUrl, 'ico')" rounded="circle" :blank="!facePictureUrl" width="40" height="40" blank-color="#777" :alt="$t('[[[Zdjęcie użytkownika]]]')" />
            </template>
            <ideo-dropdown-item @click.prevent="profile()" :to="{ name: 'core-account-profile' }">
                <i class="fa fa-fw fa-user me-2"></i> <span class="ms-2">{{ $t('[[[Profil użytkownika]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item @click.prevent="password()" :to="{ name: 'core-account-password' }">
                <i class="fa fa-fw fa-wrench me-2"></i> <span class="ms-2">{{ $t('[[[Zmiana hasła]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item @click.prevent="notifications()" :to="{ name: 'core-account-notifications' } ">
                <i class="fa fa-fw fa-bell me-2"></i> <span class="ms-2">{{ $t('[[[Powiadomienia]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-item @click.prevent="logs()">
                <i class="fa fa-fw fa-history me-2"></i> <span class="ms-2">{{ $t('[[[Logi]]]') }}</span>
            </ideo-dropdown-item>
            <ideo-dropdown-divider></ideo-dropdown-divider>
            <substitutions />
            <locales />
            <div class="dropdown-divider"></div>
            <ideo-dropdown-item @click.prevent="logout()" :to="{ name: 'dashboard' }">
                <i class="fa fa-fw fa-lock me-2"></i> <span class="ms-2">{{ $t('[[[Wyloguj się]]]') }}</span>
            </ideo-dropdown-item>
        </ideo-dropdown>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Substitutions from '@/views/partials/layout/Substitutions.vue';
import Locales from '@/views/partials/layout/Locales.vue';
import IdeoDropdown from '@/components/bootstrap/dropdown/IdeoDropdown.vue';
import { Ref } from '@/helpers/Decorators';
import ReportsService from '@/modules/core/reports/services/ReportsService';

@Options({
    components: {
        'substitutions': Substitutions,
        'locales': Locales
    }
})
export default class Account extends Vue
{
    @Ref()
    public menu: () => IdeoDropdown;

    public visible: boolean = false;

    public get facePictureUrl(): string
    {
        return this.$auth.user()?.facePicture ? this.$auth.user().facePicture.url : null;
    }

    public mounted(): void
    {
        window.addEventListener('click', this.click);
    }

    public unmounted(): void
    {
        window.removeEventListener('click', this.click);
    }

    public profile(): void
    {
        this.redirect({ name: 'core-account-profile' });
        this.click();
    }

    public password(): void
    {
        this.redirect({ name: 'core-account-password' });
        this.click();
    }

    public notifications(): void
    {
        this.redirect({ name: 'core-account-notifications' });
        this.click();
    }

    public async logs(): Promise<void>
    {
        const fileBlob = await ReportsService.getLogs();
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'errorlog.txt'); // or any other filename you want
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public logout(): void
    {
        this.redirect({ name: 'dashboard' });
        this.$auth.logout();
        this.click();
    }

    public click(): void
    {
        this.menu().hide();
    }

    public onToggle(): void
    {
        this.visible = !this.visible;
    }
}
</script>

<style lang="scss">
#account {
    .navbar-toggler {
        img {
            padding: 1px;
            border: 1px solid transparent;
        }

        &:hover img {
            border-color: var(--bs-primary);
        }
    }

    .dropdown-menu {
        z-index: var(--ideo-dropdown-zindex);
    }
}
</style>
