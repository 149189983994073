<template>
    <div class="dashboard">
        <div class="row tiles-gap">
            <div class="col-md-3">
                <ProductSumTile />
            </div>
            <div class="col-md-3">
                <UncheckedProductsTile />
            </div>
            <div class="col-md-3">
                <ModifiedProductsTile />
            </div>
            <div class="col-md-3">
                <ChangedPricesTile />
            </div>
            <div class="col-md-3">
                <ChangedAccessibilityTile />
            </div>
            <div class="col-md-3">
                <ShopsTile />
            </div>
            <div class="col-md-3">
                <DeletedProductsTile />
            </div>
            <div class="col-md-3">
                <ImportsTile />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { components } from '@/components/forms/Definitions';
import ProductSumTile from '@/modules/core/home/views/partials/ProductSumTile.vue';
import ModifiedProductsTile from '@/modules/core/home/views/partials/ModifiedProductsTile.vue';
import ChangedPricesTile from '@/modules/core/home/views/partials/ChangedPricesTile.vue';
import ChangedAccessibilityTile from '@/modules/core/home/views/partials/ChangedAccessibilityTile.vue';
import ShopsTile from '@/modules/core/home/views/partials/ShopsTile.vue';
import DeletedProductsTile from '@/modules/core/home/views/partials/DeletedProductsTile.vue';
import ImportsTile from '@/modules/core/home/views/partials/ImportsTile.vue';
import UncheckedProductsTile from '@/modules/core/home/views/partials/UncheckedProductsTile.vue';

import { Options, Vue } from "vue-class-component";

@Options({
    name: "dashboard",
    components: {
        ProductSumTile,
        ModifiedProductsTile,
        ChangedPricesTile,
        ChangedAccessibilityTile,
        ShopsTile,
        DeletedProductsTile,
        ImportsTile,
        UncheckedProductsTile
    }
})


export default class Dashboard extends Vue
{

}
</script>
<style lang="scss" scoped>
.tiles-gap {
    gap: 20px 0;
}

.column-gap {
    gap: 20px 0;
}
</style>
