<template>
    <draggable v-model="children" :move="move" item-key="id" tag="ul" class="w-100" v-if="isExpanded">
        <template #item="{element}">
            <ideo-tree-node :pages="pages" :expanded="expanded" :node="element" :menu="menu" :active="active" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort">
                <template #toggle="{node}">
                    <slot name="toggle" :node="node"></slot>
                </template>
                <template #icon="{node}">
                    <slot name="icon" :node="node"></slot>
                </template>
                <template #default="{node}">
                    <slot name="default" :node="node"></slot>
                </template>
            </ideo-tree-node>
        </template>
    </draggable>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import Draggable from 'vuedraggable';
import { TreeNode } from '../utils';

@Options({
    name: 'ideo-tree-nodes',
    components: {
        'draggable': Draggable
    }
})
export default class IdeoTreeNodes extends Vue
{
    @Prop() public parent!: TreeNode;
    @Prop() public pages!: TreeNode[];
    @Prop() public expanded!: number[];
    @Prop() public menu: (node: TreeNode) => Promise<any[]>;
    @Prop() public active: (node: TreeNode) => boolean;
    @Prop() public move: (e: any) => boolean;

    public get children(): TreeNode[]
    {
        return sortBy(filter(this.pages, p => p.parentId === (this.parent ? this.parent.id : null)), s => s.position);
    }

    public set children(pages: TreeNode[])
    {
        forEach(pages, (page: any, i: number) => { page.position = i + 1; });

        this.onSort(pages);
    }

    public get isExpanded(): boolean
    {
        return this.parent == null || (this.parent.hasChildren && this.expanded.indexOf(this.parent.id) >= 0);
    }

    @Emit('toggle')
    public onToggle(node: any): any
    {
        return node;
    }

    @Emit('click')
    public onClick(node: any): any
    {
        return node;
    }

    @Emit('delete')
    public onDelete(node: any): any
    {
        return node;
    }

    @Emit('sort')
    public onSort(pages: any[]): any[]
    {
        return pages;
    }
}
</script>
