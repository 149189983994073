<script lang="ts" setup>
import { computed, watch } from 'vue';
import FieldAutocomplete from './FieldAutocomplete.vue';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import FormsService from '@/modules/studio/forms/services/FormsService';

defineOptions({
    name: 'field-form',
    components: {
        'field-autocomplete': FieldAutocomplete
    }
});

const props = defineProps({
  "label": null,
  "category": null,
  "modelValue": null,
  "invalidFeedback": { type: Function,  },
  "disabled": { type: Boolean, default: false }
});

const emit = defineEmits(["update:modelValue"]);

const triggerUpdate = (value: number): void =>
{
    emit('update:modelValue', value);
};

const model = computed({
    get(): number
    {
        return props.modelValue;
    },
    set(value: number)
    {
        triggerUpdate(value);
    }
});

const fetchForms = async (id: number): Promise<Option<number>> =>
{
    if (props.category > 0)
    {
        const pager = new Pager(1, 1, 'Title', 'ASC');
        const response = await FormsService.getOptions(pager, props.category, id, null);

        if (response?.items?.length == 1)
        {
            return {
                value: response.items[0].value,
                text: response.items[0].text
            };
        }
    }

    return null;
};

const searchForms = async (query: string, limit: number): Promise<Option<number>[]> =>
{
    if (props.category > 0)
    {
        const pager = new Pager(1, limit, 'Title', 'ASC');
        const response = await FormsService.getOptions(pager, props.category, null, query);

        return response.items.map(p =>
        {
            return {
                value: p.value,
                text: p.text
            };
        });
    }

    return [];
};

const category = computed(() => props.category);

watch(category, (value: number, old: number): void =>
{
    if (value != old)
    {
        model.value = null;
    }
});
</script>

<template>
    <field-autocomplete v-model="model" :label="label" :fetch="fetchForms" :search="searchForms" :invalid-feedback="invalidFeedback" :disabled="disabled" />
</template>
